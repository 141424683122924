

const API_BASE_URL = process.env.REACT_APP_JURIS_API_URL;

export function fetchData(endpoint, method = 'GET', data = null, token = '', headers = {}) {
  return new Promise((resolve, reject) => {
    const config = {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...headers,
      },
      credentials: "include",
    };

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (data && method.toUpperCase() !== 'GET') {
      config.body = JSON.stringify(data);
    }

    fetch(`${API_BASE_URL}/${endpoint}`, config)
  .then((response) => {
    const status = response.status; 
    
    // Vérifier si la réponse n'a pas de contenu (204 No Content)
    if (status === 204 || status === 304) {
      resolve({
        result: null,
        status
      });
      return;
    }

    if (status >= 200 && status < 300) {
      return response.json().then((result) => {
        resolve({
          result,
          status
        });
      });
    } else if (status >= 400 && status < 500) {
      return response.json().then((error) => {
        reject({
          message: error.message || 'Une erreur côté client s\'est produite',
          status
        });
      });
    } else if (status >= 500) {
      return response.json().then((error) => {
        reject({
          message: error.message || 'Une erreur côté serveur s\'est produite',
          status
        });
      });
    } else {
      reject({
        message: 'Une erreur inattendue s\'est produite',
        status
      });
    }
  })
  .catch((error) => {
    reject({
      message: error.message || 'Une erreur de réseau s\'est produite',
    });
  });

   });
}

