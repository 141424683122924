
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import Path from "../../../assets/icons/Path.png";
import circleNike from "../../../assets/icons/circleNike.png";
import downForm from "../../../assets/icons/downForm.png";
import { useEffect, useRef, useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import { UserTablesJuridictions } from "../../repeatableComponents/Table5/UserTablesJuridictions";
import { MainHeaderTypesJuridictions } from "../../repeatableComponents/atomes/mainHeader/MainHeaderTypesJuridictions";
import { fetchData } from "../../../assets/helpers/fetchData";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";

export const MainTablesJuridictions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [etat, setEtat] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [idJuridictions, setIdJuridictions] = useState("");
    const [firstName, setFirstName] = useState("");
    const [court, setCourt] = useState("");
    const [lastName, setLastName] = useState("");
    const [position, setPosition] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [positionId, setPositionId] = useState("");
    const [courtId, setCourtId] = useState("");
    const [positionName, setPositionName] = useState("");
    const [optionVisiblePositions, setOptionVisiblePosition] = useState(false);
    const [optionVisibleTribunal, setOptionVisibleTribunal] = useState(false);
    const [optionVisibleRole, setOptionVisibleRole] = useState(false);
    const [rotateIconPosition, setRotateIconPosition] = useState(false);
    const [rotateIconRole, setRotateIconRole] = useState(false);
    const [rotateIconTribunal, setRotateIconTribunal] = useState(false);
    const [positionData, setPositionData] = useState([]);
    const [dataTribunal, setDataTribunal] = useState([]);
    const [loading, setLoading] = useState("");
    const [number, setNumber] = useState("");
    const dataJuridictions = JSON.parse(localStorage.getItem("dataJuridictions"));
    const token = localStorage.getItem('token')
    const dataRole = ["editor","administrator","reader"]
    const selectRefPosition = useRef();
    const selectRefTribunal = useRef();
    const selectRefRole = useRef()
    function fetchDataPositions(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setPositionData(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlPositions = "positions/list"
        fetchDataPositions(urlPositions)
    }, [])
    function fetchDataTribunal(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setDataTribunal(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlTribunal = "courts/list"
        fetchDataTribunal(urlTribunal)
    }, [])
    const masque = () => {
        return setEtat(true)
    }
    const closePopup = () => {
        setEtat(false)
        setOpenEyes(false);
    }
    const handleSelectPosition = () => {
        let select = selectRefPosition.current
        setRotateIconPosition(!rotateIconPosition);
        if (rotateIconPosition) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisiblePosition(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisiblePosition(true);
        }
    }
    const handleSelectTribunal = () => {
        let select = selectRefTribunal.current
        setRotateIconTribunal(!rotateIconTribunal);
        if (rotateIconTribunal) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleTribunal(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleTribunal(true);
        }
    }
    const handleSelectRole = () => {
        let select = selectRefRole.current
        setRotateIconRole(!rotateIconRole);
        if (rotateIconRole) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleRole(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleRole(true);
        }
    }
    const handleClickPosition = (value, id) => {
        setPositionName(value)
        setOptionVisiblePosition(false);
        setPositionId(id);   
    }
    const handleClickTribunal = (value, id) => {
        setCourt(value)
        setOptionVisibleTribunal(false);
        setCourtId(id);   
    }
    const handleClickRole = (value) => {
        setRole(value)
        setOptionVisibleRole(false);   
    }
    const openDocument = (id) => {
        setEtat(true);
        setOpenEyes(true);
        setIdJuridictions(id)
        const data = dataJuridictions.result.data.find((element) => element.id === id)
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setRole(data.role)
        setEmail(data.email)
        setPositionName(data.position.name)
        setCourt(data.court.name)
    }
    const handleInput = async (e) =>{
        e.preventDefault();
        const fields = [
            { name: 'Nom', value: firstName },
            { name: 'prenom', value: lastName },
            { name: 'email', value: email },
            { name: 'role', value: role },
            { name: 'tribunaux', value: court },
            { name: 'métier', value: positionName },
            { name: 'number', value: number },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const dataInfosActor = {
            firstName:firstName,
            lastName: lastName,
            courtId: courtId,
            positionId:positionId,
            email:email,
            role:role,
            phoneNumber:number
        }
       
        try {

            setLoading(true);
            const result = await fetchData(`users/${idJuridictions}`, "PUT", dataInfosActor, token)
            if (result.status === 200) {
                 snackbbar(document.querySelector("#body"), circleNike, "Modification reussie", 2000);
                 setTimeout(() => {
                    fetchData(
                        "users?sorts%5Busers.first_name%5D=asc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("dataJuridictions", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
        
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        const fields = [
            { name: 'Nom', value: firstName },
            { name: 'prenom', value: lastName },
            { name: 'email', value: email },
            { name: 'role', value: role },
            { name: 'tribunaux', value: court },
            { name: 'métier', value: positionName },
             { name: 'télephone', value: number },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        
        const dataInfosActor = {
            firstName:firstName,
            lastName: lastName,
            courtId: courtId,
            positionId:positionId,
            email:email,
            role:role,
            phoneNumber:number
        }
       
        try {

            setLoading(true);
            const result = await fetchData("users", "POST", dataInfosActor, token)
            if (result.status === 201) {
                 snackbbar(document.querySelector("#body"), circleNike, "Acteur crée avec succès", 2000);
                 setTimeout(() => {
                    fetchData(
                        "users?sorts%5Busers.first_name%5D=asc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("dataJuridictions", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="main_accueil">
            {etat && <div className="popup_wrapper">
                <div id="masque"></div>
                <form id="answer_form_judiciaire" onSubmit={handleSubmit}>
                    {etat && openEyes ? (
                        <HeaderForm
                            text={`Acteur judiciaire ${idJuridictions}`}
                            closePopup={closePopup} />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouvel Acteur Judiciaire"
                                closePopup={closePopup} />
                        )
                    )}
                    <div className="parent_answer_form_judiciaire">
                        <div className="answer_form_judiciaire1">
                            <div className="children_form">
                                <label htmlFor="">Noms</label>
                                <input type="text" placeholder="Noms" value={firstName} onChange = {(e)=>setFirstName(e.target.value)}/>
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Prenoms</label>
                                <input type="text" placeholder="prenoms" value={lastName} onChange = {(e)=>setLastName(e.target.value)}/>
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Adresse Mail</label>
                                <input type="email" placeholder="franck@gmail.com" value={email} onChange = {(e)=>setEmail(e.target.value)}/>
                            </div>
                            <div className="children_form" onClick={handleSelectPosition} ref={selectRefPosition}>
                                <label htmlFor="">Position(Metier)</label>
                                <input type="text" placeholder="Magistrat" value={positionName}/>
                                <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconPosition && "rotate(180deg)" }} />
                                {optionVisiblePositions && (
                                    <div className="optionCity">
                                        {
                                            positionData?.map((element) => {
                                                return (
                                                    <span key={element.id} onClick={() => handleClickPosition(element.name, element.id)} >
                                                        {element.name}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Numero de télephone</label>
                                <input type="number" placeholder="+23769*****" value={number} onChange = {(e)=>setNumber(e.target.value)}/>
                            </div>
                            
                        </div>
                        <div className="answer_form_judiciaire2">
                            <div className="children_form" onClick={handleSelectTribunal} ref={selectRefTribunal}>
                                <label htmlFor="">Nom du tribunal</label>
                                <input type="text" placeholder="Tribunal de Yaounde" value={court}/>
                                <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconTribunal && "rotate(180deg)" }}/>
                                {optionVisibleTribunal && (
                                    <div className="optionCity">
                                        {
                                            dataTribunal?.map((element) => {
                                                return (
                                                    <span key={element.id} onClick={() => handleClickTribunal(element.name, element.id)} >
                                                        {element.name}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="children_form" onClick={handleSelectRole} ref={selectRefRole}>
                                <label htmlFor="">Role</label>
                                <input type="text" placeholder="Edition" value={role}/>
                                <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconRole && "rotate(180deg)" }}/>
                                {optionVisibleRole && (
                                    <div className="optionCity">
                                        {
                                            dataRole?.map((element,index) => {
                                                return (
                                                    <span key={index} onClick={() => handleClickRole(element)} >
                                                        {element}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        {loading ? (
                            <div className="parent_button_tribunal_judiciare">
                                <span className="button_tribunal_judiciaire11"></span>
                                <button className="button_tribunal_judiciaire2" type="submit">En cours ...</button>
                            </div>

                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <div className="parent_button_tribunal_judiciare">
                                        <span className="button_tribunal_judiciaire11"></span>
                                        <span className="button_tribunal_judiciaire2" onClick={handleInput}>modifier les informations</span>
                                    </div>
                                ) : (
                                    <div className="parent_button_tribunal_judiciare">
                                        <button className="button_tribunal_judiciaire1" onClick={closePopup}>Annuler</button>
                                        <button className="button_tribunal_judiciaire2" type="submit">Ajouter Tribunal</button>
                                    </div>

                                )}
                            </>
                        )}


                    </div>

                    {/* <HeaderForm text="Nouveau Tribunal" closePopup={closePopup} />
                <div className="children_form">
                    <label htmlFor="">Nom du Tribunal</label>
                    <input type="text" placeholder="Nom du tribunal" />
                </div>
                <div className="children_form">
                    <label htmlFor="">Type de Tribunal</label>
                    <input type="text" placeholder="Haute Instance" />
                    <img src={downForm} alt="" className="down_form" />
                </div>
                <div className="children_form">
                    <label htmlFor="">Juridiction(s)</label>
                    <input type="text" placeholder="Juridiction(s)" />
                    <img src={downForm} alt="" className="down_form" />
                </div>
                <div className="children_form">
                    <label htmlFor="">Ville</label>
                    <input type="text" placeholder="Ville" />
                    <img src={downForm} alt="" className="down_form" />
                </div>
                <div className="parent_textarea">
                    <label htmlFor="">Adresse</label>
                    <textarea placeholder="Adresse" />
                </div>
                <div className="parent_button_tribunal">
                    <span></span>
                    <span className="button_tribunal">Ajouter Un Tribunal</span>
                </div> */}


                </form>
            </div>}
            <MainHeaderTypesJuridictions text="Acteurs Judiciaires" />
            <div className="all_filter">
                <UpdateTribunal masque={masque} text="+ Ajouter un nouvel acteur"/>
                {/* <div className="parent_filter_judiciares">
                    <span className="sous_filter_Document">Filtrer par</span>
                    <div className="checkbox_filter_judiciaires">
                        <span className="checkbox_filter_judiciaires_span">Pays</span>
                        <img src={Path} alt="" />
                    </div>
                    <div className="checkbox_filter_judiciaires">
                        <span className="checkbox_filter_judiciaires_span">Tribunal</span>
                        <img src={Path} alt="" />
                    </div>
                    <div className="checkbox_filter_judiciaires">
                        <span className="checkbox_filter_judiciaires_span">Role</span>
                        <img src={Path} alt="" />
                    </div>
                    <span className="sous_filter2_document">Tout afficher</span>
                </div> */}

            </div>
            <UserTablesJuridictions t1="Identifiant" t2="Noms" t3="Prenoms" t4="Adresse Mail" t5="Pays" t6="Tribunal" t7="Role" t8="Actions" openDocument={openDocument} />

        </div>
    )
}