import { useState } from "react"
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm"
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal"
import "../mainTribunaux/mainTribunaux.css"
import { UserTableCategories } from "../../repeatableComponents/Table5/UserTableCategories"
import remove from "../../../assets/icons/remover.png"
import "./mainCategories.css"
import { MainHeaderCategories } from "../../repeatableComponents/atomes/mainHeader/MainHeaderCategories"
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import circleNike from "../../../assets/icons/circleNike.png"
import { fetchData } from "../../../assets/helpers/fetchData"
import close from "../../../assets/icons/close.png"
import rafiki from "../../../assets/logos/rafiki.png"
import { RemovePopup } from "../../repeatableComponents/Table5/RemovePopup"

export const MainCategories = () => {
    const [etat, setEtat] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [idCategories, setIdCategories] = useState("");
    const [loading, setLoading] = useState(false);
    const [sousCategoriesId, setSousCategoriesId] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const [description, setDescription] = useState([]);
    const dataCategories = JSON.parse(localStorage.getItem("resultCategories"));
    const messageCategories ="catégorie crée avec succès"
    const putCategories = "categorie modifiée avec succès"
    const removeSubCategories = "suppression reussie"
    const notRemove = "vous ne pouvez pas suppprimer une sous categorie qui contient des fichiers"
    const token = localStorage.getItem("token")
    // console.log(dataCategories.result.data)
    const [sousCategories, setSousCategories] = useState([
        { id: 1, name: '' }
    ]);
    const masque = () => {
        return setEtat(true)
    }
    const closePopup = () => {
        setEtat(false)
        setOpenEyes(false)
        setDescription([])
    }
    const openDocument = (id) => {
        setIdCategories(id);
        const dataCategoriesId = dataCategories.result.data.filter((element) => element.id === id)
        console.log(dataCategoriesId)
        setDescription(dataCategoriesId)
        setEtat(true);
        setOpenEyes(true);
    }

    const handleNameChange = (e) => {
        // Si description[0] n'existe pas encore, on l'initialise
        const updatedDescription = [...description];
        if (!updatedDescription[0]) {
            updatedDescription[0] = { name: "", description: "", subCategories: [] };
        }
        updatedDescription[0].name = e.target.value;
        setDescription(updatedDescription);
    };

    const handleDescriptionChange = (e) => {
        // Si description[0] n'existe pas encore, on l'initialise
        const updatedDescription = [...description];
        if (!updatedDescription[0]) {
            updatedDescription[0] = { name: "", description: "", subCategories: [] };
        }
        updatedDescription[0].description = e.target.value;
        setDescription(updatedDescription);
    };
    const handleAddEmptySubCategory = () => {
        const updatedDescription = [...description];
        if (description[0]) {
            updatedDescription[0].subCategories = [
                ...updatedDescription[0].subCategories,
                { id: Date.now(), name: "" },
            ];
            setDescription(updatedDescription);
        }
    };
    const handleAddSousCategories = () => {
        setSousCategories([...sousCategories, { id: sousCategories.length + 1, name: "", description: "" }])
    }
    const handleRemoveSousCategories = (id) => {
        setSousCategories(sousCategories.filter((theme) => theme.id !== id));
    };
    const handleSousCategoryChange = (index, newValue) => {
        const updatedSousCategories = [...sousCategories];
        updatedSousCategories[index].name = newValue;
        setSousCategories(updatedSousCategories);
    };
    
    const handleSousCategory = (id, newValue) => {
        let updatedDescription = [...description];
        let subCategoryIndex = updatedDescription[0].subCategories.findIndex((element) => element.id === id);
        
        // Si l'élément existe
        if (subCategoryIndex !== -1) {
            // Mettre à jour la valeur du name de la sous-catégorie
            updatedDescription[0].subCategories[subCategoryIndex].name = newValue;
            setDescription(updatedDescription);
        }
    };
       
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(sousCategories[0].name)
        const fields = [
            { name: 'Nom de la juridiction', value: description[0]?.name },
            { name: 'description', value: description[0]?.description },
            { name: 'sousCategories', value: sousCategories[0].name },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const data = {
            subCategories: sousCategories,
            name: description[0]?.name,
            description: description[0]?.description,
        }
        try {

            setLoading(true);
            const result = await fetchData("categories", "POST", data, token)
            if (result.status === 201) {
                 snackbbar(document.querySelector("#body"), circleNike, messageCategories, 2000);
                 setTimeout(() => {
                    fetchData(
                        "categories?sorts%5Bcategories.created_at%5D=desc1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("resultCategories", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedCategories"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    }
    const handleInput = async (e) =>{
        e.preventDefault();
        const fields = [
            { name: 'Nom de la juridiction', value: description[0]?.name },
            { name: 'description', value: description[0]?.description },
            { name: 'sousCategories', value: description[0].subCategories[0].name },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const data = {
            subCategories:description[0]?.subCategories ,
            name: description[0]?.name,
            description: description[0]?.description,
        }
        try {

            setLoading(true);
            const result = await fetchData(`categories/${idCategories}`, "PUT", data, token)
            if (result.status === 200) {
                 snackbbar(document.querySelector("#body"), circleNike, putCategories, 2000);
                 setTimeout(() => {
                    fetchData(
                        "categories?sorts%5Bcategories.created_at%5D=desc1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("resultCategories", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedCategories"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    }
    const removeModificationsSousCategories = async()=>{
        fetchData(`categories/${sousCategoriesId}`,"Delete",null,token).then((response) =>{
            if(response.status === 204){
                snackbbar(document.querySelector("#body"), circleNike, removeSubCategories, 2000);
                let updatedDescription = [...description];
            let subCategoryIndex = updatedDescription[0].subCategories.findIndex((element) => element.id === sousCategoriesId);
            if (subCategoryIndex !== -1) {
                updatedDescription[0].subCategories.splice(subCategoryIndex, 1);
                  setDescription(updatedDescription);
            }
              setOpenRemove(false)
              setEtat(true)
              setOpenEyes(true)
              
              
      
            }
      
          }).catch((error) =>{
            console.log(error)
            if(error.status === 400){
              snackbbar(document.querySelector("#body"),circleNike,notRemove, 2000);
              setEtat(true)
              setOpenEyes(true)
              setOpenRemove(false)
            }
          })
       
    }
    const openStateRemove = (id) => {
            setOpenRemove(true);
            setSousCategoriesId(id);
            setEtat(false)
            setOpenEyes(false)
      }
      const closePopupRemove = () => {
        setOpenRemove(false)
      }
    return (
        <div className="main_accueil">
             {openRemove && <div className='masqueOpenModal'></div>}
             {openRemove && <RemovePopup close={close} closePopupRemove={closePopupRemove} handleRemoveCategories={removeModificationsSousCategories} text="Voulez vous supprimer cette sous categories" rafiki={rafiki} text2="Confirmer la suppression" />}
            {etat && <div className="popup_wrapper">
                <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                    {etat && openEyes ? (
                        <HeaderForm
                            text={`Categorie ${idCategories}`}
                            closePopup={closePopup} />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouvelle Categorie"
                                closePopup={closePopup} />
                        )
                    )}
                    <div className="children_form">
                        <label htmlFor="">Nom de la Categorie</label>
                        <input type="text" placeholder="Nom de la juridiction" value={etat && description[0] && openEyes ? description[0].name : description[0]?.name || ""} onChange={handleNameChange} />
                    </div>
                    <div className="parent_textarea">
                        <label htmlFor="">Description</label>
                        <textarea placeholder="Description" value={etat && description[0] && openEyes ? description[0].description : description[0]?.description || ""} onChange={handleDescriptionChange} />
                    </div>
                    <div className="parent_update_categorie">
                        <div className="update_categorie">
                            <span className="sous_categorie">Sous Categorie(s)</span>
                            <span className="update_sous_categorie" onClick={
                                etat && openEyes
                                    ? handleAddEmptySubCategory
                                    : handleAddSousCategories
                            }>+ Ajouter</span>
                        </div>

                        {etat && openEyes && description[0] ? (
                            description[0].subCategories.map((element) => {
                                return (
                                    <span className="update_sous_categorie_text" key={element.id}>
                                    <input type="text" value={element.name} className="contentEditable" onChange={(e) => handleSousCategory(element.id,e.target.value)}/>
                                        <img src={remove} alt="" className="remove_categories" onClick={ () =>openStateRemove(element.id)}/>
                                    </span>
                                )
                            })) : (
                            sousCategories.map((element, index) => {
                                return (
                                    <span className="update_sous_categorie_text" key={index}>
                                        <input type="text" value={element.name} className="contentEditable" onChange={(e) => handleSousCategoryChange(index,e.target.value)}/>
                                        <img src={remove} alt="" className="remove_categories" onClick={() => handleRemoveSousCategories(element.id)} />
                                    </span>
                                )
                            })
                        )}
                    </div>
                    <div className="parent_button_tribunal">
                        <span></span>
                        {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal" onClick = {handleInput}>Enregistrer les modifications</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter la Categorie</button>
                                )}
                            </>
                        )}
                    </div>


                </form>
            </div>}
            <MainHeaderCategories text="Categories" />
            <div className="all_filter">
                <UpdateTribunal masque={masque} text="+ Ajouter une categorie" />
            </div>
            <UserTableCategories t1="" t2="Identifiant" t3="Nom de la categorie" t4="Nombre de Document" t5="Action" openDocument={openDocument} />

        </div>
    )
}