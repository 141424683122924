
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import Path from "../../../assets/icons/Path.png";
import circleNike from "../../../assets/icons/circleNike.png";
import downForm from "../../../assets/icons/downForm.png";
import { useEffect, useRef, useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import { fetchData } from "../../../assets/helpers/fetchData";
import { UserTableCity } from "../../repeatableComponents/Table5/UserTableCity";
import { MainHeaderCity } from "../../repeatableComponents/atomes/mainHeader/mainHeaderCity";


export const MainVille = () => {
    const [etat, setEtat] = useState(false);
    const [idCity, setIdCity] = useState("");
    const [countryId, setCountryId] = useState("");
    const [countryName, setCountryName] = useState("");
    const [loading, setLoading] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [cityName, setCityName] = useState("");
    const [optionVisibleCountries, setOptionVisibleCountries] = useState(false);
    const [rotateIconCountries, setRotateIconCountries] = useState(false);
    const [dataCountries, setDataCountries] = useState([]);
    const token = localStorage.getItem("token");
    let selectRefCountries = useRef()
   
    const masque= ()=> {
       return setEtat(true)
    }
    const closePopup = ()=>{
        setEtat(false);
        setOpenEyes(false);
    }
    const openDocument = (id,nameCountries,nameCity) => {
        setEtat(true);
        setOpenEyes(true);
        setCityName(nameCity)
        setCountryName(nameCountries)
        setIdCity(id)
    }
    function fetchDataUrlCountry(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setDataCountries(result?.result?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        const urlCountries = "countries/list"
        fetchDataUrlCountry(urlCountries)
    }, [])
      const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = [
            { name: 'pays', value: countryName },
            { name: 'ville', value: cityName },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const data = {
            countryId:countryId,
            name:cityName,  
        }
       
        try {

            setLoading(true);
            const result = await fetchData("cities", "POST", data, token)
            if (result.status === 201) {
                 snackbbar(document.querySelector("#body"), circleNike,"Enregistrement reussi", 2000);
                 setTimeout(() => {
                    fetchData(
                        "cities?sorts%5Bcreated_at%5D=desc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("city", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedCity"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
}
const handleInput = async (e) => {
    e.preventDefault();
    const fields = [
        { name: 'pays', value: countryName },
        { name: 'ville', value: cityName },
    ];

    for (let i = 0; i < fields.length; i++) {
        if (!fields[i].value) {
            const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
            return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

        }
    }
    const data = {
        countryId:countryId,
        name:cityName ,  
    }
   
    try {

        setLoading(true);
        const result = await fetchData(`cities/${idCity}`, "PUT", data, token)
        if (result.status === 200) {
             snackbbar(document.querySelector("#body"), circleNike, "modification reussie", 2000);
             setTimeout(() => {
                fetchData(
                    "cities?sorts%5Bcreated_at%5D=desc&page=1",
                    "GET",
                    null,
                    token
                  ).then((response) => {
                    localStorage.setItem("city", JSON.stringify(response));
                    window.dispatchEvent(new Event("localStorageUpdatedCity"));
                    setEtat(false);
                  });
            }, 2000);
        }
    } catch (error) {
        console.error(error.message);

    } finally {
        setLoading(false);
    }
}
const handleSelectCountries = () => {
    let select = selectRefCountries.current
    setRotateIconCountries(!rotateIconCountries);
    if (rotateIconCountries) {
         select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
        setOptionVisibleCountries(false);
    } else {
        select.style.borderBottomRightRadius = "0px";
        select.style.borderBottomLeftRadius = "0px";
        setOptionVisibleCountries(true);
    }
}
const handleClickCountries = (value,id) => {
    const select = selectRefCountries.current
    setCountryName(value);
    setOptionVisibleCountries(false);
    setRotateIconCountries(!rotateIconCountries);
    setCountryId(id)
    select.style.borderBottomRightRadius = "10px";
    select.style.borderBottomLeftRadius = "10px";
};
    return (
        <div className="main_accueil">
             {etat &&<div className="popup_wrapper">
             <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                {/* <HeaderForm text ="Nouveau Pays" closePopup={closePopup}/> */}
                {etat && openEyes ? (
                        <HeaderForm
                            text={`Ville ${idCity}`}
                            closePopup={closePopup} />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouvelle Ville"
                                closePopup={closePopup} />
                        )
                    )}
                <div className="children_form" onClick={handleSelectCountries} ref={selectRefCountries}>
                    <label htmlFor="">Pays</label>
                    <input type="text" placeholder="Pays" value={countryName}/>
                    <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconCountries && "rotate(180deg)" }}/>
                                {optionVisibleCountries && (
                                    <div className="optionCity">
                                        {
                                            dataCountries?.map((element) => {
                                                return (
                                                    <span key={element.id} onClick={() => handleClickCountries(element.name, element.id)} >
                                                        {element.name}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                </div>
                <div className="children_form">
                    <label htmlFor="">Nom de la Ville</label>
                    <input type="text" placeholder="Cameroun" value={cityName} onChange={(e) =>setCityName(e.target.value)}/>
                </div>
                <div className="parent_button_tribunal">
                    <span></span>
                    {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal" onClick = {handleInput}>Enregistrer les modifications</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter le Pays</button>
                                )}
                            </>
                        )}
                </div>
                
                

            </form>
            </div>}
            <MainHeaderCity text="Ville" />
            <div className="all_filter">
                <UpdateTribunal masque={masque} text="+ Ajouter une Ville"/>
                {/* <div className="parent_filter_CityMainHeaderCity">
                    <span className="sous_filter1">Filtrer par</span>
                    <div className="checkbox_filter">
                        <span className="checkbox_filter_span">Noms</span>
                        <img src={Path} alt="" />
                    </div>
                    <span className="sous_filter2">Tout afficher</span>
                </div> */}
            </div>
            <UserTableCity t1="Identifiant" t2="Code du Pays" t3="Nom de Ville" t4="Action" openDocument={openDocument}/>

        </div>
    )
}