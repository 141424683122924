
import './table.css';
import eyes from "../../../assets/icons/eyes.png";
import remover from "../../../assets/icons/remover.png";
import circleNike from "../../../assets/icons/circleNike.png";
import rafiki from "../../../assets/logos/rafiki.png"
import close from "../../../assets/icons/close.png"
import { useEffect, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { Pagination } from '../atomes/pagination/Pagination';
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';
import { RemovePopup } from './RemovePopup';

export const UserTablesJuridictionsReader = ({ t1, t2, t3, t4, t5, t6}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [openRemoveTypesJuridictions,setOpenRemoveTypesJuridictions] = useState(false);
  const [userId,setUserId] = useState("");
  const [isError, setIsError] = useState(false);
  const token = localStorage.getItem("token")
  const messageRemove = "suppression reussie";
 
  const retryInterval = 5000;

  const fetchDataByUrl = (url) => {
    fetchData(url, "GET", null, token)
      .then((result) => {
        const data = result?.result?.data;
        console.log(data)
        setUsers(data);
        const meta = result?.result?.meta;
        setTotalPages(meta?.lastPage || 1);
        setNextPageUrl(meta?.nextPageUrl || null);
        setPrevPageUrl(meta?.previousPageUrl || null);
        setCurrentPage(meta?.currentPage || 1);
        setIsError(false); // Réinitialise l'erreur si les données sont bien reçues
      })
      .catch((error) => {
        console.error("Erreur de connexion :", error);
        if (error.message === "Failed to fetch") {
          setIsError(true);
          // Réessaie après 5 secondes
          setTimeout(() => {
            fetchDataByUrl(url); // Relance la requête
          }, retryInterval);
        }
      });
  };
  // Fonction pour charger les données en temps reel dans mon composant MainHeader
  const loadLocalStorageData = () => {
    const data = JSON.parse(localStorage.getItem("dataJuridictions"));
    if (data) {
      console.log(data);
      const meta = data?.result?.meta;
      console.log(meta)
      setUsers(data?.result?.data);
      setTotalPages(meta?.lastPage || 1);
      setNextPageUrl(meta?.lastPageUrl);
      setPrevPageUrl(meta?.previousPageUrl);
      setCurrentPage(meta?.currentPage || 1);
      setIsError(false);
    }

  };
  
  useEffect(() => 
    {
    const initialUrl = "users?sorts%5Busers.first_name%5D=asc&page=1";
    fetchDataByUrl(initialUrl);
    // Écouter l'événement en temps reel du tableau contenu dans mon composant de MainHeader
    const handleLocalStorageUpdate = () => {
      loadLocalStorageData();
    };

     window.addEventListener("localStorageUpdatedTypesJuridictions", handleLocalStorageUpdate);

    // // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener("localStorageUpdatedTypesJuridictions", handleLocalStorageUpdate);
   };
  }, []);
  const handlePageClick = (pageNumber) => {
    const searchInput = localStorage.getItem("searchInputJuridiction"); // Récupère la valeur de searchInput du localStorage
  
    // Si searchInput est défini et non vide, génère une URL avec le paramètre search
    const specificPageUrl = searchInput
      ? `users?sorts%5Busers.first_name%5D=asc&search=${searchInput}&page=${pageNumber}`
      : `users?sorts%5Busers.first_name%5D=asc&page=${pageNumber}`;
  
    // Utilise cette URL pour faire la requête
    fetchDataByUrl(specificPageUrl);
  };
  const handleNextClick = () => {
    if (nextPageUrl) {
      fetchDataByUrl(nextPageUrl);
    }
  };

  const handlePrevClick = () => {
    if (prevPageUrl) {
      fetchDataByUrl(prevPageUrl);
    }
  };
  const handleRemoveTypesJuridictions = () => {
    const initialUrl = "users?sorts%5Busers.first_name%5D=asc&page=1";
    fetchData(`users/${userId}`, "Delete", null, token).then((response) => {
      console.log(response)
      if (response.status === 204) {
        snackbbar(document.querySelector("#body"), circleNike, messageRemove, 2000);
        setOpenRemoveTypesJuridictions(false);
        setTimeout(() => {
          fetchDataByUrl(initialUrl);
        }, 3000);

      }

    }).catch((error) => {
      console.log(error)
      // if (error.status === 400) {
      //   return snackbbar(document.querySelector("#body"), circleNike, notRemove, 2000);
      // }
    })
  }
  const openStateRemove = (id) => {
    setOpenRemoveTypesJuridictions(true)
    setUserId(id)
  }
  const closePopupRemove = () => {
    setOpenRemoveTypesJuridictions(false)
  }

  return (
    <>
    {isError ? (
      <div className="error_message_connexion">
        Désolé mauvaise connexion svp actualiser la page...
      </div>
    ) : (
    <>
     {openRemoveTypesJuridictions && <div className='masqueOpenModal'></div>}
     {openRemoveTypesJuridictions && <RemovePopup close={close} closePopupRemove = {closePopupRemove} handleRemoveCategories = {handleRemoveTypesJuridictions} text ="Confirmer la suppression d'un Acteur" rafiki={rafiki} text2 = "Acteur supprimé avec succès"/>}
    <table className="user-table">
      <thead>
        <tr>
          <th>{t1}</th>
          <th>{t2}</th>
          <th>{t3}</th>
          <th>{t4}</th>
          <th>{t5}</th>
          <th>{t6}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={index}>
            <td title={user.id}>{user.id}</td>
            <td title={user.firstName}>{user.firstName}</td>
            <td title={user.lastName}>{user.lastName}</td>
            <td title={user.position.name}>{user.position.name}</td>
            <td title={user.email}>{user.email}</td>
            <td title={user.role}>{user.role}</td>
          </tr>
        ))}
      </tbody>
    </table>
     <Pagination
     totalPages={totalPages}
     currentPage={currentPage}
     onPageChange={handlePageClick}
     prevPageUrl={prevPageUrl}
     nextPageUrl={nextPageUrl}
     handlePrevClick={handlePrevClick}
     handleNextClick={handleNextClick}
   />
   </>
     )}
    </>
  );
};


