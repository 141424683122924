
import { Connect } from "./components/componentNotRetulables/connect/Connect";
import { Header } from "./components/componentNotRetulables/header/Header";
import { MainAccueil } from "./components/componentNotRetulables/mainAccueil/MainAccueil";
import { MainAdmin } from "./components/componentNotRetulables/mainAdmin/MainAdmin";
import { MainCategories } from "./components/componentNotRetulables/mainCategories/MainCategories";
import { MainCountries } from "./components/componentNotRetulables/mainCountries/MainCountries";
import { MainDocuments } from "./components/componentNotRetulables/mainDocuments/MainDocuments";
import { MainDocumentsReader } from "./components/componentNotRetulables/mainDocuments/MainDocumentsReader";
import { MainFaqs } from "./components/componentNotRetulables/mainFaqs/MainFaqs";
import { MainJuridictions } from "./components/componentNotRetulables/mainJuridictions/MainJuridictions";
import { MainTablesJuridictions } from "./components/componentNotRetulables/mainTablesJuridictions/MainTablesJuridictions";
import { MainTribunaux } from "./components/componentNotRetulables/mainTribunaux/MainTribunaux";
import { MainTypes } from "./components/componentNotRetulables/mainTypes/MainTypes";
import { MainVille } from "./components/componentNotRetulables/mainVille/MainVille";
import { SideBar } from "./components/componentNotRetulables/sideBar/SideBar";
import { NotificationsProvider } from "./components/repeatableComponents/atomes/NotificationsProvider";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { useEffect, useState } from "react";
import { EditeurDocuments } from "./components/componentNotRetulables/editeur/EditeurDocuments";
import { SideBarReader } from "./components/componentNotRetulables/sideBar/SlideBarReader";
import { HeaderReader } from "./components/componentNotRetulables/header/HeaderReader";
import { MainAccueilReader } from "./components/componentNotRetulables/mainAccueil/MainAccueilReader";
import { MainTablesJuridictionsReader } from "./components/componentNotRetulables/mainTablesJuridictions/MainTablesJuridictionsReader";
import { MainFaqsReader } from "./components/componentNotRetulables/mainFaqs/MainFaqsReader";
import { SideBarEditor } from "./components/componentNotRetulables/sideBar/SideBarEditor";
import { MainDocumentsEditor } from "./components/componentNotRetulables/mainDocuments/MainDocumentsEditor";
import { HeaderEditor } from "./components/componentNotRetulables/header/HeaderEditor";
// // Composants Reader
// import { ReaderDashboard } from "./components/componentReader/ReaderDashboard";
// import { ReaderViewDocument } from "./components/componentReader/ReaderViewDocument";

function App() {
  const [role, setRole] = useState(null);

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    setRole(storedRole);
  }, []);

  return (
    <BrowserRouter>
      <NotificationsProvider>
        <div className="App" id="body">
          {role === "reader" && (
            <>
              <SideBarReader />
              <HeaderReader />
            </>
          )}
          {role === "editor" && (
            <>
             <SideBarEditor/>
              <HeaderEditor /> 
            </>
          )}

          {role === "administrator" && (
            <>
              <SideBar />
              <Header />
            </>
          )}
          <div className="main">
            <Routes>
              <Route path="/" element={<Connect />} />
              <Route path="/home" element={<PrivateRoute roleRequired="administrator">
                <MainAccueil />
              </PrivateRoute>} />
              <Route path="/tribunaux" element={<PrivateRoute roleRequired="administrator"><MainTribunaux /></PrivateRoute>} />
              <Route path="/types" element={<PrivateRoute roleRequired="administrator"><MainTypes /></PrivateRoute>} />
              <Route path="/juridictions" element={<PrivateRoute roleRequired="administrator"><MainJuridictions /></PrivateRoute>} />
              <Route path="/categories" element={<PrivateRoute roleRequired="administrator"><MainCategories /></PrivateRoute>} />
              <Route path="/document" element={<PrivateRoute roleRequired="administrator"><MainDocuments /></PrivateRoute>} />
              <Route path="/admin" element={<PrivateRoute roleRequired="administrator"><MainAdmin /></PrivateRoute>} />
              <Route path="/actor" element={<PrivateRoute roleRequired="administrator"><MainTablesJuridictions /></PrivateRoute>} />
              <Route path="/countries" element={<PrivateRoute roleRequired="administrator"><MainCountries /></PrivateRoute>} />
              <Route path="/city" element={<PrivateRoute roleRequired="administrator"><MainVille /></PrivateRoute>} />
              <Route path="/faqs" element={<PrivateRoute roleRequired="administrator"><MainFaqs /></PrivateRoute>} />
              <Route
                path="/reader"
                element={
                  <PrivateRoute roleRequired="reader">
                    <MainAccueilReader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reader/bibliotheques"
                element={
                  <PrivateRoute roleRequired="reader">
                    <MainDocumentsReader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reader/user"
                element={
                  <PrivateRoute roleRequired="reader">
                    <MainTablesJuridictionsReader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reader/faq"
                element={
                  <PrivateRoute roleRequired="reader">
                    <MainFaqsReader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editor"
                element={
                  <PrivateRoute roleRequired="editor">
                    <MainAccueilReader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editor/bibliotheques"
                element={
                  <PrivateRoute roleRequired="editor">
                    <MainDocumentsEditor />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editor/user"
                element={
                  <PrivateRoute roleRequired="editor">
                    <MainTablesJuridictionsReader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editor/faq"
                element={
                  <PrivateRoute roleRequired="editor">
                    <MainFaqsReader />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </NotificationsProvider>
    </BrowserRouter>
  );
}

export default App;

