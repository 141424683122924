
import './table.css'; 
import favoris from "../../../assets/icons/favoris.png";
import eyes from "../../../assets/icons/eyes.png";
import remover from "../../../assets/icons/remover.png";
import { useEffect, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { Pagination } from '../atomes/pagination/Pagination';
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';
import circleNike from "../../../assets/icons/circleNike.png";
import { RemovePopup } from './RemovePopup';
import close from "../../../assets/icons/close.png"
import rafiki from "../../../assets/logos/rafiki.png"

export const UserJuridictions = ({t1,t2,t3,openDocument}) => {
  const token = localStorage.getItem("token");
  const [users,setUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 
  const [nextPageUrl, setNextPageUrl] = useState(null); 
  const [prevPageUrl, setPrevPageUrl] = useState(null); 
  const [openRemoveDocument, setOpenRemoveDocument] = useState(false);
  const [userId, setUserId] = useState("");
  const [isError,setIsError] = useState(false);
    const messageRemove = "suppression reussie";
  const notRemove = "vous ne pouvez pas supprimer une juridiction ayant un tribunal";


  const fetchDataByUrl = (url) => {
    fetchData(url, "GET", null, token)
      .then((result) => {
        console.log(result)
        setUsers(result?.result?.data)
        const meta = result?.result?.meta;
        setTotalPages(meta?.lastPage || 1);
        setNextPageUrl(meta?.nextPageUrl || null);
        setPrevPageUrl(meta?.previousPageUrl || null);
        setCurrentPage(meta?.currentPage || 1);
      })
      .catch((error) => {
        console.error("Erreur de connexion :", error);
          if(error.message === "Failed to fetch"){
            return  setIsError(true) 
          }    
      });
  };
  const loadLocalStorageData = () => {
    const data = JSON.parse(localStorage.getItem("resultJuridictions"));
    if (data) {
      console.log(data);
      const meta = data?.result?.meta;
      setUsers(data?.result?.data);
       setTotalPages(meta?.lastPage || 1);
       setNextPageUrl(meta?.nextPageUrl || null);
       setPrevPageUrl(meta?.previousPageUrl || null);
       setCurrentPage(meta?.currentPage || 1);
       setIsError(false);
    }
    
  };
  useEffect(() => {
    const initialUrl = "jurisdictions?page=1";
    fetchDataByUrl(initialUrl);
    // Écouter l'événement en temps reel du tableau contenu dans mon composant de MainHeader
    const handleLocalStorageUpdate = () => {
      loadLocalStorageData();
    };

     window.addEventListener("localStorageUpdatedJuridictions",handleLocalStorageUpdate);

  //  // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener("localStorageUpdatedJuridictions",handleLocalStorageUpdate);
    };
  }, []);
  const removeCourts = () => {
    const initialUrl = "jurisdictions?page=1";
    fetchData(`jurisdictions/${userId}`,"Delete",null,token).then((response) =>{
      if(response.status === 204){
        snackbbar(document.querySelector("#body"),circleNike,messageRemove, 2000);
        setOpenRemoveDocument(false)
        setTimeout(() => {
          fetchDataByUrl(initialUrl);
      }, 3000);

      }

    }).catch((error) =>{
      console.log(error)
      if(error.status === 400){
      return  snackbbar(document.querySelector("#body"),circleNike,notRemove, 2000);
      }
    })
  }
  const handleNextClick = () => {
    if (nextPageUrl) {
      fetchDataByUrl(nextPageUrl);
    }
  };

  const handlePrevClick = () => {
    if (prevPageUrl) {
      fetchDataByUrl(prevPageUrl); 
    }
  };

  const handlePageClick = (pageNumber) => {
    const specificPageUrl = `jurisdictions?page=${pageNumber}`;
    fetchDataByUrl(specificPageUrl);
  };
  const openStateRemove = (id) => {
    setOpenRemoveDocument(true)
    setUserId(id)
  }
  const closePopupRemove = () => {
    setOpenRemoveDocument(false)
  }

  return (
    <>
      {isError ? ( 
        <div className="error_message_connexion">
           Désolé mauvaise connexion svp actualiser la page... 
        </div>
      ) : (
        <>
         {openRemoveDocument && <div className='masqueOpenModal'></div>}
         {openRemoveDocument && <RemovePopup close={close} closePopupRemove={closePopupRemove} handleRemoveCategories={removeCourts} text="Voulez supprimer cette juridiction" rafiki={rafiki} text2="Confirmer la suppression" />}
          <table className="user-table">
            <thead>
              <tr>
                <th>{t1}</th>
                <th>{t2}</th>
                <th>{t3}</th>
              </tr>
            </thead>
            <tbody>
              {users?.length > 0 ? (
                users?.map((user) => (
                 
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td title={user.name}>{user.name}</td>
                    <td>
                      <img src={eyes} alt="" onClick={(e) => openDocument(user.id,user.name)}/>
                      <img src={remover} alt="" className='remover' onClick={(e) => openStateRemove(user.id)}/>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="no-results">Aucun élément ne correspond à cette recherche</td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageClick}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
          />
        </>
      )}
    </>
  );
};





