import { MainHeader } from "../../repeatableComponents/atomes/mainHeader/MainHeader";
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import Path from "../../../assets/icons/Path.png";
import "./mainDocuments.css";
import { Pagination } from "../../repeatableComponents/atomes/pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import downForm from "../../../assets/icons/downForm.png";
import closeSelect from "../../../assets/icons/closeSelect.png"
import calendar from "../../../assets/icons/calendar.png";
import fileImage from "../../../assets/logos/fileImage.png"
import document from "../../../assets/icons/document.png";
import star from "../../../assets/icons/star.png";
import close from "../../../assets/icons/close.png";
import remover from "../../../assets/icons/remover.png";
import closeChips from "../../../assets/icons/closeChips.png"
import starYellow from "../../../assets/icons/starYellow.png"
import { UserTableDocuments } from "../../repeatableComponents/Table5/UserTableDocuments";
import { MainHeaderDocuments } from "../../repeatableComponents/atomes/mainHeader/MainHeaderDocuments";
import { fetchData } from "../../../assets/helpers/fetchData";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import circleNike from "../../../assets/icons/circleNike.png"
import { DetailsDocuments } from "../detailsDocuments/DetailsDocuments";
import { CheckDate } from "../../../assets/helpers/CheckDate";
import { useSearchCountries } from "../../../customsHooks/useSearchCountries";
import { useSearchCity } from "../../../customsHooks/useSearchCity";
import { useSearchType } from "../../../customsHooks/useSearchType";
import { SnackBar } from "../../repeatableComponents/atomes/SnackBar";
import { NotificationDisplay } from "../NotificationDisplay";
import { UserTableDocumentsReader } from "../../repeatableComponents/Table5/UserTableDocumentsReader.jsx";

export const MainDocumentsReader = () => {
    const dataStatus = [{ name: "pending" }, { name: "approved" }]
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [checkStar, setCheckStar] = useState(false);
    const [file, setFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [check2Date, setCheck2Date] = useState(false)
    const [etat, setEtat] = useState(false);
    const [showUploader, setShowUploader] = useState(true); // Commence par montrer l'input de téléchargement
    const [popupOpen, setPopupOpen] = useState(false);
    const [categories, setCategories] = useState("");
    const [categoriesId, setCategoriesId] = useState(false);
    const [dataCategories, setDataCategories] = useState([])
    const [cityData, setCityData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [optionVisibleCategories, setOptionVisibleCategories] = useState(false);
    const [rotateIconCategories, setRotateIconCategories] = useState(false);
    const [tribunal, setTribunal] = useState("");
    const [TribunalId, setTribunalId] = useState(false);
    const [dataTribunal, setDataTribunal] = useState([])
    const [optionVisibleTribunal, setOptionVisibleTribunal] = useState(false);
    const [rotateIconTribunal, setRotateIconTribunal] = useState(false);
    const [auteursInput, setAuteursInput] = useState('');
    const [judgementNumber, setJudgementNumber] = useState('');
    const [description, setDescription] = useState('');
    const [documentId, setDocumentId] = useState("");
    const [etatInput, setEtatInput] = useState(false);
    const [etatDocument, setEtatDocument] = useState(false);
    const [loading, setLoading] = useState(false);
    const [writeCountries, setWriteCountries] = useState(false);
    const [rotateIconCity, setRotateIconCity] = useState(false);
    const [rotateIconType, setRotateIconType] = useState(false);
    const [rotateIconStatus, setRotateIconStatus] = useState(false);
    const [checkEtatCity, setCheckEtatCity] = useState(false);
    const [checkEtatType, setCheckEtatType] = useState(false);
    const [checkEtatStatus, setCheckEtatStatus] = useState(false);
    const [etatInputCountries, setEtatInputCountries] = useState(false);
    const [optionVisibleCountries, setOptionVisibleCountries] = useState(false);
    const [optionVisibleCity, setOptionVisibleCity] = useState(false);
    const [optionVisibleType, setOptionVisibleType] = useState(false);
    const [optionVisibleStatus, setOptionVisibleStatus] = useState(false);
    const [countries, setCountries] = useState(null);
    const [city, setCity] = useState("");
    const [type, setType] = useState("");
    const [statusDocument, setStatusDocument] = useState("pending")
    const [rotateIconCountries, setRotateIconCountries] = useState(false);
    const [etatInputCity, setEtatInputCity] = useState(false);
    const [etatInputType, setEtatInputType] = useState(false);
    const [etatInputStatus, setEtatInputStatus] = useState(false);
    const [auteurs, setAuteurs] = useState([]);
    const token = localStorage.getItem("token");
    const selectRefCategories = useRef(null);
    const selectRefTribunal = useRef(null);
    const [chips, setChips] = useState([]);
    const [countriesData, setCountriesData] = useState([]);
    const [searchResults, searchCountries] = useSearchCountries(countriesData);
    const [searchCity, searchCheckCity] = useSearchCity(cityData);
    const [searchType, searchCheckType] = useSearchType(typeData);
    const [filterSelected, setFilterSelected] = useState(null);
    const [checkCountries, setCheckCountries] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [check, setCheck] = useState(false);
    const [idDoc,setIdDoc] = useState("")
    const [values, setValues] = useState(undefined);
    const dateInputRef = useRef(null);
    const selectRefCity = useRef(null);
    const selectRefType = useRef(null);
    const selectRefStatus = useRef(null);
    const selectRefCountries = useRef(null)
    const BASE_URL = 'https://staging.api.data-lex.net';
    // const checkRef = useRef(null);
    // const checkColor = useRef(null);
    console.log(countriesData)
    const messageFormData = "document créé avec succès"
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    function fetchDataCity(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                //console.log(result?.result?.data)
                setCityData(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlCity = "cities/all"
        fetchDataCity(urlCity)
    }, [])
    const handleClickCity = (value) => {
        const select = selectRefCity.current
        setCity(value);
        setOptionVisibleCity(false);
        setCheckEtatCity(false);
        setRotateIconCity(!rotateIconCity);
        searchCheckCity(value);
        select.style.borderBottomRightRadius = "10px";
        select.style.borderBottomLeftRadius = "10px";
    };
    const handleClickType = (value) => {
        const select = selectRefType.current
        setType(value);
        setOptionVisibleType(false);
        setCheckEtatType(false);
        setRotateIconType(!rotateIconType);
        searchCheckType(value);
        select.style.borderBottomRightRadius = "10px";
        select.style.borderBottomLeftRadius = "10px";
    };
    const handleClickStatus = (value) => {
        const select = selectRefStatus.current
        setStatusDocument(value);
        setOptionVisibleStatus(false);
        setRotateIconStatus(!rotateIconStatus);
        setCheckEtatStatus(true)
        select.style.borderBottomRightRadius = "10px";
        select.style.borderBottomLeftRadius = "10px";
        localStorage.setItem("documentStatus", JSON.stringify(value))
        fetchData(`files?statuses[0]=${value}&sorts%5Bfiles.created_at%5D=desc`, "GET", null, token).then((response) => {
            console.log(response);
            localStorage.setItem("document", JSON.stringify(response));
            window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
        })
    };
    
    const openDocumentDetails = (id) => {
        console.log(id)
        localStorage.setItem("IdDocumentsUser",id)
        setDocumentId(id);
        setEtatDocument(true);
    }
    const closeSelectInputCity = () => {
        setEtatInputCity(false);
        setOptionVisibleCity(false);
        setRotateIconCity(false);
    }
    const closeSelectInputType = () => {
        setEtatInputType(false);
        setOptionVisibleType(false);
        setRotateIconType(false);
    }
    const backDocument = () => {
        setEtatDocument(false);
    }
    const handleSelectCategories = () => {
        let select = selectRefCategories.current
        setRotateIconCategories(!rotateIconCategories);
        if (rotateIconCategories) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleCategories(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleCategories(true);
        }
    }
    const handleClickCategories = (value, id) => {
        console.log(value)
        const select = selectRefCategories.current
        setCategories(value)
        setOptionVisibleCategories(false);
        setCategoriesId(id);
        //  select.style.borderBottomRightRadius = "10px";
        //  select.style.borderBottomLeftRadius = "10px";
    }
    const handleSelectTribunal = () => {
        let select = selectRefTribunal.current
        setRotateIconTribunal(!rotateIconTribunal);
        if (rotateIconTribunal) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleTribunal(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleTribunal(true);
        }
    }
    const handleClickTribunal = (value, id) => {
        console.log(value)
        const select = selectRefTribunal.current
        setTribunal(value)
        setOptionVisibleTribunal(false);
        setTribunalId(id);
        //select.style.borderBottomRightRadius = "10px";
        //select.style.borderBottomLeftRadius = "10px";
    }
    const masque = () => {
        return setEtat(true)
    }
    const closePopup = () => {
         setEtat(false)
         setTribunal("")
         setCategories("")
         setJudgementNumber("")
         setDescription("")
         setSelectedDate("")
         setCheckStar(false)
         setAuteurs([])
         setChips([])
         setAuteursInput("")      
    }
   

    const handlePictureChange = (e) => {
        const files = Array.from(e.target.files);
        const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const validFiles = files.filter(file => imageTypes.includes(file.type));

        if (validFiles.length > 0) {
            setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
            setShowUploader(false);
        }
    };
    const handlePdfChange = (e) => {
        const file = e.target.files[0];

        if (file && file.type === 'application/pdf') {
            setPdfFile(file);
            setShowUploader(false)

        }
    };
    const resetSelectionPdf = () => {
        setSelectedFiles([]);
        setPdfFile(null);
        setShowUploader(true);
    };


    const resetSelection = () => {
        setPdfFile(null);
        setShowUploader(true);
    };

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopupImages = () => {
        setPopupOpen(false);
    };
    const removeImage = (index) => {
        console.log(index)
        let updatedRemove = [...selectedFiles];
        const remove = updatedRemove?.filter((_, i) => i !== index)
        return setSelectedFiles(remove)
    };
    const handleChangeSelectCity = () => {
        setRotateIconCity(!rotateIconCity);
        if (rotateIconCity) {
            setEtatInputCity(false);
            setOptionVisibleCity(false);
            setCheckEtatCity(false)
        } else {
            setEtatInputCity(true)
            setOptionVisibleCity(true)
        }
    }
    const handleChangeSelectType = () => {
        setRotateIconType(!rotateIconType);
        if (rotateIconType) {
            setEtatInputType(false);
            setOptionVisibleType(false);
            setCheckEtatType(false)
        } else {
            setEtatInputType(true)
            setOptionVisibleType(true)
        }
    }
    const handleChangeSelectStatus = () => {
        setRotateIconStatus(!rotateIconStatus);
        if (rotateIconStatus) {
            setEtatInputStatus(false);
            setOptionVisibleStatus(false);
        } else {
            setEtatInputStatus(true)
            setOptionVisibleStatus(true)
            // setCheckEtatStatus(false)
        }
    }
    const handleChangeSelect = () => {
        setRotateIconCountries(!rotateIconCountries);
        const select = selectRefCountries.current
        if (rotateIconCountries) {
            setEtatInputCountries(false);
            setOptionVisibleCountries(false);
            setWriteCountries(false)

        } else {
            setEtatInputCountries(true)
            setOptionVisibleCountries(true)
        }
    }
    function fetchDataUrl(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                console.log(result?.result?.data)
                setCountriesData(result?.result?.data)
                localStorage.setItem("countriesCity", JSON.stringify(result))
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function fetchDataUrlCity(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                // console.log(result?.result?.data)
                // setCityData(result?.result?.data)
                ///localStorage.setItem("countriesCity", JSON.stringify(result))
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        const urlCountries = "countries/list"
        fetchDataUrlCity(urlCountries)
    }, [])
    useEffect(() => {
        const urlCountries = "countries/list"
        fetchDataUrl(urlCountries)
    }, [])
    const handleClick = (value, element) => {
        const select = selectRefCountries.current
        setCountries(value);
        setOptionVisibleCountries(false);
        setWriteCountries(false)
        setRotateIconCountries(!rotateIconCountries);
        searchCountries(value);
        console.log(element.cities)
        setCityData(element?.cities)
        select.style.borderBottomRightRadius = "10px";
        select.style.borderBottomLeftRadius = "10px";
    };

    function fetchDataCategories(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                console.log(result?.result?.data)
                setDataCategories(result?.result?.data)
                setTypeData(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlCategories = "categories/list"
        fetchDataCategories(urlCategories)
    }, [])
    function fetchDataTribunal(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                //console.log(result?.result?.data)
                setDataTribunal(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlTribunal = "courts?sorts%5Bcourts.created_at%5D=desc"
        fetchDataTribunal(urlTribunal)
    }, [])
    const handleInputChange = (e) => {
        setAuteursInput(e.target.value);
    };

    const handleBlur = () => {
        // Convertit la chaîne de caractères en tableau en séparant par des virgules
        const authorsArray = auteursInput.split(',').map(auteur => auteur.trim()).filter(auteur => auteur);
        setAuteurs(authorsArray); // Met à jour le tableau d'auteurs
    };
    const handleAddChip = (inputValue) => {
        const newChips = inputValue.split(',')
            .map(word => word.trim())
            .filter(word => word.length > 0 && !chips.includes(word)); // Filtrer les doublons

        setChips(prevChips => [...prevChips, ...newChips]); // Ajout des nouveaux chips sans doublons
    };
    const handleRemoveChip = (indexToRemove) => {
        setChips(prevChips => prevChips.filter((chip, index) => index !== indexToRemove));
    };
    const openStar = () => {
        setCheckStar(true);
    };
    const closeStar = () => {
        setCheckStar(false);
    };
    const subscribeToChannel = async (channel) => {
        try {
          const response = await fetch(`${BASE_URL}/__transmit/subscribe`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid:token, channel }), 
          });
    
          if (response.ok) {
            console.log(`Souscription réussie au canal ${channel}`);
          } else {
            console.error('Échec de la souscription:', response.statusText);
          }
        } catch (error) {
          console.error('Erreur lors de la souscription:', error);
        }
      };


    const handleSubmit = async (e) => {
        console.log(pdfFile)
        e.preventDefault();

        const formData = new FormData();


        selectedFiles.forEach((file, index) => {
            formData.append(`images[]`, file);
        });

        if (pdfFile) {
            formData.append('document', pdfFile);
        }
        formData.append('courtId', TribunalId || '');
        formData.append('categoryId', categoriesId);
        formData.append('judgementNumber', judgementNumber || '');
        formData.append('judgementDate', selectedDate ? selectedDate.toISOString().split('T')[0] : '');
        auteurs.forEach((author) => formData.append('authors[]', author));
        chips.forEach((tag) => formData.append('tags[]', tag));



        formData.append('description', description || '');
        formData.append('isFavourite', checkStar);

        try {
            setLoading(true)
            console.log(formData)
            const response = await fetch('https://staging.api.data-lex.net/files', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                console.log('Fichier téléversé avec succès');
                console.log(response)
                response.json().then((result) => {
                    const channel = `files/${result.data.id}/textract-completion`;
                    if (result.status === "success") {
                        setShowSnackBar(true);
                        setTimeout(() => {
                            fetchData(
                                "files?sorts%5Bfiles.created_at%5D=desc&page=1",
                                "GET",
                                null,
                                token
                            ).then((response) => {
                                console.log(response);
                                localStorage.setItem("document", JSON.stringify(response));
                                window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
                                setEtat(false);
                                subscribeToChannel(channel);
                            });
                        }, 2000);
                    }
                })
            } else {
                console.error('Échec du téléversement du fichier');
            }
        } catch (error) {
            console.error(error.message);

        }finally{
            setLoading(false);
        }
    };
    const closeSelectInput = () => {
        setEtatInputCountries(false)
        setOptionVisibleCountries(false)
        setRotateIconCountries(false)
    }
    const checkDates = () => {
        setCheck2Date(!check2Date)
    }

    useEffect(() => {
        if (searchCity.length >= 1) {
            setValues(city); // Si la ville est définie, c'est la valeur active
        } else if (searchType.length >= 1) {
            setValues(type); // Si le type est défini, il devient la valeur active
        } else if (searchResults.length >= 1) {
            setValues(countries); // Si les résultats des pays sont définis, ils deviennent la valeur active
        } else if (dataStatus.length >= 1 && etatInputStatus) {
            setValues(statusDocument);
        }
        else {
            setValues(undefined); // Si aucun filtre n'est défini, la valeur est undefined
        }
    }, [city, type, countries, searchCity, searchType, searchResults]);


    const displayAllDocuments = () => {
        localStorage.setItem("updateAllDocuments", true);
        window.dispatchEvent(new Event("updateAllDocuments"));
    };
    const handleFilterSelection = (filter) => {
        setFilterSelected(filter); // Met à jour le filtre sélectionné
    };
 


    return (
        <>
            {etatDocument ? <DetailsDocuments id={documentId} backDocument={backDocument} etatDocument={etatDocument}/> :
                <div className="main_accueil">
                    {etat && <div className="popup_wrapper">
                        <div id="masque"></div>
                        {/* Affichage de la snackbar */}
                        {showSnackBar && (
                            <SnackBar
                                message="Document créé avec succès"
                                image={circleNike}
                                timeToHide={2000}
                                onClose={() => setShowSnackBar(false)} // Ferme la snackbar après l'expiration du temps
                            />
                        )}
                    </div>}
                    {/* <MainHeaderDocuments text="Documents" values={searchCity.length >= 1 ? city : (searchResults.length >= 1 ? countries : undefined)} />  onClick={() => checkDates()}*/}
                    <MainHeaderDocuments text="Documents" values={values} />


                    <div className="all_filter">
                        <span></span>
                        {/* <UpdateTribunal masque={masque} text="+ Ajouter un Document" /> */}
                        <div className="parent_filter_document_reader">
                            <span className="sous_filter_Document">Filtrer par</span>
                            <div className={`checkbox_filter_document ${filterSelected === 'date' ? 'active-filter' : ''}`} onClick={() => { handleFilterSelection('date'); checkDates(); }}>
                                <span className="checkbox_filter_document_span">Date</span>
                                <img src={calendar} alt="" />
                            </div>
                            <div className={`checkbox_filter_document ${filterSelected === 'categories' ? 'active-filter' : ''}`}
                                onClick={() => { handleFilterSelection('categories'); handleChangeSelectType(); }}>
                                <span className="checkbox_filter_document_span">Categories</span>
                                <img src={Path} alt="" style={{ transform: rotateIconType && "rotate(180deg)" }} />
                            </div>
                            <span className={`sous_filter2_document ${filterSelected === 'allDoc' ? 'active-filter' : ''}`}
                                onClick={() => { handleFilterSelection('allDoc'); displayAllDocuments(); }}>Tout afficher</span>
                        </div>
                    </div>
                    <div className="select_parent_filter">
                        <div className="select_parent_filter1">
                            {etatInputCountries && <input type="text" className="select_input" value={countries} ref={selectRefCountries} onChange={(e) => {
                                setCountries(e.target.value)
                                searchCountries(e.target.value)
                                setWriteCountries(true)
                                setOptionVisibleCountries(false)
                            }} />}
                            {etatInputCountries && <img src={closeSelect} alt="" className="close_select" onClick={closeSelectInput} />}
                            {optionVisibleCountries && (
                                <div className="option">
                                    {
                                        countriesData?.map((element) => {
                                            return (
                                                <span key={element.id} onClick={() => handleClick(element.name, element)}>
                                                    {element.name}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {writeCountries && (
                                <div className="option">
                                    {searchResults.length > 0 &&
                                        searchResults.map((element) => (
                                            <span key={element.id} onClick={() => handleClick(element.name, element)}>
                                                {element.name}
                                            </span>
                                        ))
                                    }

                                    {searchResults.length === 0 && (
                                        <span className="no-countries">Aucun résultat</span>
                                    )}
                                </div>

                            )}
                        </div>
                        <div className="select_parent_filter2">
                            {etatInputCity && <input type="text" className="select_input" value={city} ref={selectRefCity} onChange={(e) => {
                                setCity(e.target.value)
                                searchCheckCity(e.target.value)
                                setCheckEtatCity(true)
                                setOptionVisibleCity(false)

                            }} />}
                            {etatInputCity && <img src={closeSelect} alt="" className="close_select" onClick={closeSelectInputCity} />}
                            {optionVisibleCity && (
                                <div className="option">
                                    {
                                        cityData?.map((element) => {
                                            return (
                                                <span key={element.id} onClick={() => handleClickCity(element.name)}>
                                                    {element.name}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {checkEtatCity && (
                                <div className="option">
                                    {searchCity?.length > 0 &&
                                        searchCity?.map((element) => (
                                            <span key={element.id} onClick={() => handleClickCity(element.name)}>
                                                {element.name}
                                            </span>
                                        ))
                                    }

                                    {searchCity?.length === 0 && (
                                        <span className="no-countries">Aucun résultat</span>
                                    )}
                                </div>

                            )}

                        </div>
                        <div className="select_parent_filter2">
                            {etatInputType && <input type="text" className="select_input" value={type} ref={selectRefType} onChange={(e) => {
                                setType(e.target.value)
                                searchCheckType(e.target.value)
                                setCheckEtatType(true)
                                setOptionVisibleType(false)
                            }} />}
                            {etatInputType && <img src={closeSelect} alt="" className="close_select" onClick={closeSelectInputType} />}

                            {optionVisibleType && (
                                <div className="option">

                                    {
                                        dataCategories?.map((element) => {
                                            return (
                                                <div className="countriesCity" key={element.id}>
                                                    <span className="countriesCity_span">{element.name}</span>
                                                    {
                                                        element.subCategories.map((element) => {
                                                            return (
                                                                <div className="AllcountriesCity" key={element.id}>
                                                                    <span className="AllcountriesCity_span" onClick={() => handleClickType(element.name)} >
                                                                        {element.name}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            )}
                            {checkEtatType && (
                                <div className="option">
                                    {searchType?.length > 0 &&
                                        searchType?.map((element) => (
                                            <span key={element.id} onClick={() => handleClickType(element.name)}>
                                                {element.name}
                                            </span>
                                        ))
                                    }

                                    {searchType?.length === 0 && (
                                        <span className="no-countries">Aucun résultat</span>
                                    )}
                                </div>

                            )}

                        </div>
                        <div className="select_parent_filter2">
                            {etatInputStatus && <input type="text" className="select_input" value={statusDocument} ref={selectRefStatus} />}
                            {optionVisibleStatus && (
                                <div className="option">
                                    {
                                        dataStatus?.map((element, index) => {
                                            return (
                                                <span key={index} onClick={() => handleClickStatus(element.name)}>
                                                    {element.name}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    {check2Date && <CheckDate />}

                    <UserTableDocumentsReader t1="Identifiant"  t2="Categorie" t3="Date de Jugement" t4="N* Jugement" t5="Date d 'envoi" t6="Action" openDocumentDetails={openDocumentDetails} />
                    {/* <NotificationDisplay /> */}
                </div>
            }
        </>
    )
}