
export const MainHeaderFaqs = ({ text }) => {
    return (
        <div className="parent_header">
            <span className="title_main">{text}</span>
            {/* <div className="search_input">
                <img src={search} alt="" className="search_information" />
                <input
                    type="text"
                    placeholder="Search"
                    ref={inputRef}
                    value={searchInput}
                    onChange={handleInputChange}
                />
            </div> */}
        </div>
    )
}