
import { useState, useCallback } from 'react';

export function useSearchCity(arrayCity) {
  const [searchCity, setSearchCity] = useState([]);

  const searchCheckCity = useCallback((userInformation) => {
    return new Promise((resolve) => {
      let timeOutId = null;
      clearTimeout(timeOutId);

      timeOutId = setTimeout(() => {
        if (userInformation.length >= 4) { 
          const searchNameCity = () => {
            return new Promise((resolve) => {
              const searchNameCity = arrayCity?.filter((info) => info.name);
              console.log(searchNameCity)
              const searchNamesInfosCity = searchNameCity?.filter((info) => {
                console.log(info);
                if (info.name.toLowerCase().includes(userInformation.toLowerCase())) {
                  return info;
                }
              });
              return resolve(searchNamesInfosCity);
            });
          };

          Promise.all([searchNameCity()]).then((response) => {
            const results = [...response[0]];
            const filterDoublon = [...new Set(results)];
            setSearchCity(filterDoublon);
            return resolve(filterDoublon);
          });
         } else {
           //Si la longueur de userInformation est inférieure à 4
       setSearchCity([]);
           resolve([]);
         }
      }, 300);
    });
  }, [arrayCity]);

  return [searchCity, searchCheckCity];
}
// import { useState, useCallback } from "react";

// export function useSearchCity(arrayCity) {
//   const [searchCity, setSearchCity] = useState([]);

//   const searchCheckCity = useCallback((userInformation) => {
//     return new Promise((resolve) => {
//       let timeOutId = null;
//       clearTimeout(timeOutId);

//       timeOutId = setTimeout(() => {
//         if (!arrayCity || !Array.isArray(arrayCity)) {
//           // Si arrayCity n'est pas un tableau, retourne un tableau vide
//           setSearchCity([]);
//           return resolve([]);
//         }

//         if (userInformation.length >= 4) {
//           // Recherche les villes lorsque la longueur du texte est de 4 ou plus
//           const searchNameCity = () => {
//             return new Promise((resolve) => {
//               const searchNameCity = arrayCity.filter((info) => info.name);
//               const searchNamesInfosCity = searchNameCity.filter((info) => {
//                 if (info.name.toLowerCase().includes(userInformation.toLowerCase())) {
//                   return info;
//                 }
//               });
//               resolve(searchNamesInfosCity);
//             });
//           };

//           Promise.all([searchNameCity()]).then((response) => {
//             const results = [...response[0]];
//             const filterDoublon = [...new Set(results)];
//             setSearchCity(filterDoublon);
//             resolve(filterDoublon);
//           });
//         } else {
//           // Si la longueur de userInformation est inférieure à 4 ou vide
//           setSearchCity([]);
//           resolve([]);
//         }
//       }, 300);
//     });
//   }, [arrayCity]);

//   return [searchCity, searchCheckCity];
// }
