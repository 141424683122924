
import './table.css';
import favoris from "../../../assets/icons/favoris.png";
import eyes from "../../../assets/icons/eyes.png";
import close from "../../../assets/icons/close.png";
import circleNike from "../../../assets/icons/circleNike.png";
import rafiki from "../../../assets/logos/rafiki.png";
import remover from "../../../assets/icons/remover.png";
import { Pagination } from '../atomes/pagination/Pagination';
import { useEffect, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { RemovePopup } from './RemovePopup';
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';

export const UserTableCity = ({ t1, t2, t3, t4,openDocument}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [openRemoveDocument,setOpenRemoveDocument] = useState(false);
  const [userId,setUserId] = useState("");
  const [isError, setIsError] = useState(false);
  const token = localStorage.getItem("token")
  const messageRemove = "suppression reussie";
 
  const retryInterval = 5000;

  const fetchDataByUrl = (url) => {
    fetchData(url, "GET", null, token)
      .then((result) => {
        const data = result?.result?.data;
        console.log(data)
        setUsers(data);
        const meta = result?.result?.meta;
        setTotalPages(meta?.lastPage || 1);
        setNextPageUrl(meta?.nextPageUrl || null);
        setPrevPageUrl(meta?.previousPageUrl || null);
        setCurrentPage(meta?.currentPage || 1);
        setIsError(false); // Réinitialise l'erreur si les données sont bien reçues
      })
      .catch((error) => {
        console.error("Erreur de connexion :", error);
        if (error.message === "Failed to fetch") {
          setIsError(true);
          // Réessaie après 5 secondes
          setTimeout(() => {
            fetchDataByUrl(url); // Relance la requête
          }, retryInterval);
        }
      });
  };
  // Fonction pour charger les données en temps reel dans mon composant MainHeader
  const loadLocalStorageData = () => {
    const data = JSON.parse(localStorage.getItem("city"));
    if (data) {
      console.log(data);
      const meta = data?.result?.meta;
      console.log(meta)
      setUsers(data?.result?.data);
      setTotalPages(meta?.lastPage || 1);
      setNextPageUrl(meta?.lastPageUrl);
      setPrevPageUrl(meta?.previousPageUrl);
      setCurrentPage(meta?.currentPage || 1);
      setIsError(false);
    }

  };
  
  useEffect(() => {
    const initialUrl = "cities?sorts%5Bcities.created_at%5D=desc&page=1";
    fetchDataByUrl(initialUrl);
    // Écouter l'événement en temps reel du tableau contenu dans mon composant de MainHeader
    const handleLocalStorageUpdate = () => {
      loadLocalStorageData();
    };

     window.addEventListener("localStorageUpdatedCity", handleLocalStorageUpdate);

    // // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener("localStorageUpdatedCity", handleLocalStorageUpdate);
   };
  }, []);
  const handlePageClick = (pageNumber) => {
    const searchInput = localStorage.getItem("searchInputCity"); // Récupère la valeur de searchInput du localStorage
  
    // Si searchInput est défini et non vide, génère une URL avec le paramètre search
    const specificPageUrl = searchInput
      ? `cities?sorts%5Bcities.created_at%5D=desc&search=${searchInput}&page=${pageNumber}`
      : `cities?sorts%5Bcities.created_at%5D=desc&page=${pageNumber}`;
  
    // Utilise cette URL pour faire la requête
    fetchDataByUrl(specificPageUrl);
  };
  const handleNextClick = () => {
    if (nextPageUrl) {
      fetchDataByUrl(nextPageUrl);
    }
  };

  const handlePrevClick = () => {
    if (prevPageUrl) {
      fetchDataByUrl(prevPageUrl);
    }
  };
  const handleRemoveCity = () => {
    const initialUrl = "cities?sorts%5Bcities.created_at%5D=desc&page=1";
    fetchData(`cities/${userId}`, "Delete", null, token).then((response) => {
      console.log(response)
      if (response.status === 204) {
        snackbbar(document.querySelector("#body"), circleNike, messageRemove, 2000);
        setOpenRemoveDocument(false);
        setTimeout(() => {
          fetchDataByUrl(initialUrl);
        }, 3000);

      }

    }).catch((error) => {
      console.log(error)
      // if (error.status === 400) {
      //   return snackbbar(document.querySelector("#body"), circleNike, notRemove, 2000);
      // }
    })
  }
  const openStateRemove = (id) => {
    setOpenRemoveDocument(true)
    setUserId(id)
  }
  const closePopupRemove = () => {
    setOpenRemoveDocument(false)
  }

  return (
    <>
      {isError ? (
        <div className="error_message_connexion">
          Désolé mauvaise connexion svp actualiser la page...
        </div>
      ) : (
    <>
     {openRemoveDocument && <div className='masqueOpenModal'></div>}
     {openRemoveDocument && <RemovePopup close={close} closePopupRemove = {closePopupRemove} handleRemoveCategories = {handleRemoveCity} text ="Voulez  vous valider la  suppression" rafiki={rafiki} text2 = "confirmer la suppression de la Ville"/>}
    <table className="user-table"></table>
    <table className="user-table">
      <thead>
        <tr>
          <th>{t1}</th>
          <th>{t2}</th>
          <th>{t3}</th>
          <th>{t4}</th>
        </tr>
      </thead>
      <tbody>
        {users?.map((user) => (
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.country.name}</td>
            <td>{user.name}</td>
            <td>
              <img src={eyes} alt="" onClick={() => openDocument(user.id,user.country.name,user.name)}/>
              <img src={remover} alt="" className='remover'onClick={()=>openStateRemove(user.id)}/>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <Pagination
    totalPages={totalPages}
    currentPage={currentPage}
    onPageChange={handlePageClick}
    prevPageUrl={prevPageUrl}
    nextPageUrl={nextPageUrl}
    handlePrevClick={handlePrevClick}
    handleNextClick={handleNextClick}
  />
  </>
   )}
   </>
  );
};


