import React, { useState } from "react";
import "./connect.css";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import circleNike from "../../../assets/icons/circleNike.png";
import { fetchData } from "../../../assets/helpers/fetchData";
import { useNavigate } from 'react-router-dom';
import eyesLock from "../../../assets/icons/eyesLock.png";
import eyesOpen from "../../../assets/icons/eyesOpen.png";

export const Connect = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [etat, setEtat] = useState(false);
  const navigate = useNavigate();
  const message = "Connexion réussie !";
  const errorMessage = "Mauvais mot de passe ou adresse mail";
  const errorServeur = "Probleme de connexion à signaler";
  const errorReseau = "Probleme de réseau";
  let data = {};
  const changesEyes = ()=>{
    setEtat(!etat)
}

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!email) {
      validationErrors.email = "L'adresse email est obligatoire.";
    } else if (!validateEmail(email)) {
      validationErrors.email = "L'adresse email n'est pas valide.";
    }
    if (!password) {
      validationErrors.password = "Le mot de passe est obligatoire.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      try {
        data = {
          email: email,
          password: password,
        };
        console.log(data);
        setLoading(true); 
        const result = await fetchData("token","POST",data,null)
        console.log(result)
        if (result.status === 200) {
          const role = result.result.data.user.role
          snackbbar(document.querySelector("#body"), circleNike, message, 2000);
          localStorage.setItem("token",result.result.data.token.accessToken);
          localStorage.setItem("role",result.result.data.user.role);
          setTimeout(() => {
               // Redirection en fonction du rôle
        if (role === "administrator") {
          navigate("/home");
        } else if (role === "editor") {
          navigate("/editor");
        } else if (role === "reader") {
          navigate("/reader");
        }
        }, 2000); 

        }
      } catch (error) {
        console.error(error.message);
          if(error.message === "Une erreur côté client s\'est produite"){
           return snackbbar(document.querySelector("#body"), circleNike, errorMessage, 4000);
          }
            if(error.message === "Une erreur côté serveur s\'est produite"){
            return snackbbar(document.querySelector("#body"), circleNike, errorServeur, 4000);
          }
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <div className="connect">
      <form className="form_connect" onSubmit={handleSubmit}>
        <h1 className="form_connect_title">Connexion</h1>

        <div className="children_form">
          <label htmlFor="email">Adresse Mail</label>
          <input
            type="text"
            placeholder="nth@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span className="error_message">{errors.email}</span>}
        </div>

        <div className="children_form">
          <label htmlFor="password">Mot de Passe</label>
          {etat?<input
            type="text"
            placeholder="frLm@125§"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />:<input
          type="password"
          placeholder="frLm@125§"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />}
          {etat?<img src={eyesOpen} alt="" onClick={changesEyes} className="eyes_connexion"/>:<img src ={eyesLock} alt="" onClick={changesEyes} className="eyes_connexion"/>}
          {errors.password && (
            <span className="error_message">{errors.password}</span>
          )}
        </div>

      
          {loading ?<div className="loading_content">
            <div className="loading">
            <span className="loading_span">En Cours</span>
            <div className="spinner"></div> 
            </div>
            </div>:<button className="button_tribunal_connect" type="submit">Connexion</button>} 
      </form>
    </div>
  );
};
