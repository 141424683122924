import { useState } from "react"
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm"
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal"
import "../mainTribunaux/mainTribunaux.css"
import {  UserTableTypesTribunal } from "../../repeatableComponents/Table5/UserTableTypesTribunal"
import { fetchData } from "../../../assets/helpers/fetchData"
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar"
import circleNike from "../../../assets/icons/circleNike.png"
import { MainHeaderJuridictions } from "../../repeatableComponents/atomes/mainHeader/MainHeaderJuridictions"
import { UserJuridictions } from "../../repeatableComponents/Table5/UserJuridictions"

export const MainJuridictions = () => {
    const [etat, setEtat] = useState(false);
    const [nameType,setNameType] = useState("");
    const [nameDescription,setNameDescription] = useState("");
    const [openEyes, setOpenEyes] = useState(false);
    const [loading,setLoading] = useState(false);
    const [userTypeId,setUserTypeId] = useState("");
    const token = localStorage.getItem("token")
    const typeTribunal = " Juridiction creé avec succès"
    const typeTribunalPut = "Juridiction modifiée avec succès"
    const masque= ()=> {
        return setEtat(true)
     }
    const closePopup = ()=>{
        setEtat(false);
        setOpenEyes(false);
        setNameDescription("")
        setNameType("")
    }
    const openDocument = (id,name) => {
        fetchData(`jurisdictions/${id}`, "GET", null, token)
        .then((result) => {
            setNameDescription(result?.result.data.description)
        })
        .catch((error) => {
            console.log(error);
        });
        setUserTypeId(id)
        setNameType(name)
        setEtat(true);
        setOpenEyes(true);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = [
            { name: 'Juridiction', value: nameType },
            { name: 'description juridiction', value: nameDescription },
           
        ];

        for (let i = 0; i < data.length; i++) {
            if (!data[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${data[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const datas ={
            name:nameType,
            description:nameDescription
        }
        try {

            setLoading(true);
            const result = await fetchData("jurisdictions", "POST", datas, token)
            console.log(result)
            if (result.status === 201) {
             snackbbar(document.querySelector("#body"), circleNike, typeTribunal, 2000);
             setTimeout(() => {
                fetchData(
                    "jurisdictions?page=1",
                    "GET",
                    null,
                    token
                  ).then((response) => {
                    console.log(response);
                    localStorage.setItem("resultJuridictions", JSON.stringify(response));
                    window.dispatchEvent(new Event("localStorageUpdatedJuridictions"));
                    setEtat(false)
                    setNameDescription("")
                    setNameType("")
                  });
            }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    };
    const handlePutJuridiction = async (e) => {
        e.preventDefault();
        const data = [
            { name: 'Juridiction', value: nameType },
            { name: 'description juridiction', value: nameDescription },
           
        ];

        for (let i = 0; i < data.length; i++) {
            if (!data[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${data[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const datas ={
            name:nameType,
            description:nameDescription
        }
        try {

            setLoading(true);
            const result = await fetchData(`jurisdictions/${userTypeId}`, "PUT", datas, token)
            console.log(result)
            if (result.status === 200) {
             snackbbar(document.querySelector("#body"), circleNike, typeTribunalPut, 2000);
             setTimeout(() => {
                fetchData(
                    "jurisdictions?page=1",
                    "GET",
                    null,
                    token
                  ).then((response) => {
                    console.log(response);
                    localStorage.setItem("resultJuridictions", JSON.stringify(response));
                    window.dispatchEvent(new Event("localStorageUpdatedJuridictions"));
                    setEtat(false)
                    setNameDescription("")
                    setNameType("")
                  });
            }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="main_accueil">
            {etat &&<div className="popup_wrapper">
            <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                {etat && openEyes ? (
                        <HeaderForm
                            text={`Juridiction ${userTypeId}`}
                            closePopup={closePopup}
                        />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouvelle Juridiction"
                                closePopup={closePopup}
                            />
                        )
                    )}
                <div className="children_form">
                    <label htmlFor="">Nom du Type</label>
                    <input type="text" placeholder="Nom du Type" value={nameType} onChange={(e) => setNameType(e.target.value)}/>
                </div>
                <div className="parent_textarea">
                    <label htmlFor="">Description</label>
                    <textarea  placeholder="Description"  value={nameDescription} onChange={(e) => setNameDescription(e.target.value)}/>
                </div>
                <div className="parent_button_tribunal">
                    <span></span>
                    {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal" onClick={handlePutJuridiction}>Modifier la Juridiction</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter la Juridiction</button>
                                )}
                            </>
                        )}

                </div>
                

            </form>
            </div>}
             <MainHeaderJuridictions text="Juridictions"/>
             <div className="all_filter">
                <UpdateTribunal masque={masque} text ="+ Ajouter un nouveau type"/>
            </div>
            <UserJuridictions t1="Identifiant" t2="Nom de la Juridiction" t3="Action" openDocument={openDocument}/>

        </div>
        )
    }