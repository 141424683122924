
import { useState } from "react";
import "./header.css";
import headerLeftIcons from "../../../assets/icons/headerLeftIcons.png";
import francais from "../../../assets/images/francais.png";
import francais1 from "../../../assets/images/francais1.png";
import anglais from "../../../assets/images/anglais.png";
import tchouta from "../../../assets/images/tchouta.jpg";
import down from "../../../assets/icons/down.png";
import chevron from "../../../assets/icons/chevron.png";
import comptes from "../../../assets/icons/comptes.png";
import deconnecter from "../../../assets/icons/deconnecter.png";

export const Header = () => {
    const [etat, setEtat] = useState(false);
    const [compte, setCompte] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'fr'); // Définit la langue par défaut

    const changeLanguage = () => {
        setEtat(!etat);
    };

    const changeDeconnexion = () => {
        setCompte(!compte);
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest(".header_right_left") && !event.target.closest(".header_right_right")) {
            setEtat(false);
            setCompte(false);
        }
    };

    const selectLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang); // Sauvegarde la langue sélectionnée dans le localStorage
        window.dispatchEvent(new Event("localStorageLanguage"));
        setEtat(false); // Ferme le menu de sélection de langue
    };

    return (
        <div className="header" onClick={handleClickOutside}>
            <img src={headerLeftIcons} alt="" className="header_left_img" />
            <div className="header_right">
                <div className="header_right_left">
                    <img src={language === 'fr' ? francais : anglais} alt="" />
                    <div className="parent_icons" onClick={changeLanguage}>
                        <span>{language === 'fr' ? 'Français' : 'Anglais'}</span>
                        <img src={down} alt="" />
                    </div>
                </div>
                {
                    etat && (
                        <div className="change_language">
                            <span className="change_language_span">Sélectionner la langue</span>
                            <div className="sous_change_language">
                                <div onClick={() => selectLanguage('en')}>
                                    <img src={anglais} alt="" className="language_img" />
                                    <span>Anglais</span>
                                </div>
                                <div onClick={() => selectLanguage('fr')}>
                                    <img src={francais1} alt="" className="language_img" />
                                    <span>Français</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="header_right_right">
                    <img src={tchouta} alt="" className="picture_admin" />
                    <div className="header_right_right_child2">
                        <div className="information_admin" onClick={changeDeconnexion}>
                            <span className="name_admin">Yvan Njanko</span>
                            <img src={chevron} alt="" className="down" />
                        </div>
                        <span className="role_admin">Admin</span>
                    </div>
                </div>
                {
                    compte && (
                        <div className="comptes">
                            <div className="sous_comptes">
                                <img src={comptes} alt="" />
                                <span>Gérer le Compte</span>
                            </div>
                            <div className="sous_comptes1">
                                <img src={deconnecter} alt="" />
                                <span>Se déconnecter</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};
