import { useEffect, useState } from "react";
import { StatRectangle } from "../../repeatableComponents/atomes/statRectangle/StatRectangle";
import "./mainAccueil.css"
import { fetchData } from "../../../assets/helpers/fetchData";
import { MainHeaderFavoris } from "../../repeatableComponents/atomes/mainHeader/MainHeaderFavoris";
import { UserTableFavoris } from "../../repeatableComponents/Table5/UserTableFavoris";
import { DetailsDocuments } from "../detailsDocuments/DetailsDocuments";
import { StatisticsGraph } from "../StatisticsGraph";

export const MainAccueil = () => {
    const token = localStorage.getItem("token");
    const [countriesCount, setCountriesCount] = useState("");
    const [courtsCount, setCourtsCount] = useState("")
    const [filesCount, setFilesCount] = useState("")
    const [usersCount, setUsersCount] = useState("")
    const [etatDocument, setEtatDocument] = useState(false);
    const [documentId, setDocumentId] = useState("");
    function fetchDataAccueil(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setCountriesCount(result.result.data.countriesCount);
                setCourtsCount(result.result.data.courtsCount);
                setFilesCount(result?.result?.data.filesCount)
                setUsersCount(result?.result?.data.usersCount)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const url = "accounting"
        fetchDataAccueil(url)
    }, [])
    const openDocumentDetails = (id) => {
        console.log(id)
        setDocumentId(id);
        setEtatDocument(true);
    }
    const backDocument = () => {
        setEtatDocument(false);
    }

    return (
        <>
            {etatDocument ? <DetailsDocuments id={documentId} backDocument={backDocument} etatDocument={etatDocument} /> :
                <div className="main_accueil">
                    <MainHeaderFavoris text="Tableau de Bord"/>
                    <div className="parent_details">
                        <StatRectangle text="Nombre de Pays" number={countriesCount} pourcent="5%" date="depuis le mois passé" />
                        <StatRectangle text="Nombre de Tribunaux" number={courtsCount} pourcent="35%" date="depuis le mois passé" />
                        <StatRectangle text="Nombre d 'utilisateurs" number={filesCount} pourcent="30%" date="depuis le mois passé" />
                        <StatRectangle text="Nombre de Documents" number={usersCount} pourcent="50%" date="depuis le mois passé" />
                    </div>
                    <StatisticsGraph />
                    <div className='parent_check_files'>
                        <h1 className="h1_file">Favoris</h1>
                        <div className="content_table">
                            <UserTableFavoris t1="Identifiant" t2="Pays" t3="Ville" t4="Tribunal" t5="Categorie" t6="Date" t9="Action" openDocumentDetails={openDocumentDetails} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}