import { useParams } from 'react-router-dom';
import "./detailsDocuments.css"
import buttonBack from "../../../assets/icons/buttonBack.png"
import downForm from "../../../assets/icons/downForm.png"
import file from "../../../assets/icons/file.png"
import gras from "../../../assets/icons/gras.png"
import underline from "../../../assets/icons/underline.png"
import italique from "../../../assets/icons/italique.png"
import police from "../../../assets/icons/police.png"
import link from "../../../assets/icons/link.png"
import upload from "../../../assets/icons/upload.png"
import list from "../../../assets/icons/list.png"
import star from "../../../assets/icons/star.png"
import starYellow from "../../../assets/icons/starYellow.png"
import checkNikes from "../../../assets/icons/checkNikes.png"
import closeChips from "../../../assets/icons/closeChips.png"
import reject from "../../../assets/icons/reject.png"
import { useEffect, useRef, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { formatDate } from '../../../assets/helpers/formatDate';
import { saveAs } from 'file-saver';
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';
import circleNike from "../../../assets/icons/circleNike.png"
import { SnackBar } from '../../repeatableComponents/atomes/SnackBar';

export const DetailsDocuments = ({ id, backDocument}) => {
    const [date, setDate] = useState("");
    const [tribunalName, setTribunalName] = useState("");
    const [dataTribunal, setDataTribunal] = useState([]);
    const [tribunalId, setTribunalId] = useState("");
    const [jugementNumber, setJugementNumber] = useState("");
    const [rotateIconTribunal, setRotateIconTribunal] = useState(false);
    const [optionVisibleTribunal, setOptionVisibleTribunal] = useState(false);
    const [rotateIconCategories, setRotateIconCategories] = useState(false);
    const [optionVisibleCategories, setOptionVisibleCategories] = useState(false);
    const [categoriesId, setCategoriesId] = useState("");
    const [dataCategories, setDataCategories] = useState([])
    const [jugementDate, setJugementDate] = useState("");
    const [categoryName, setCategoryName] = useState("");
    // const [category,setCategory] = useState(categoryName);
    const [authors, setAuthors] = useState([]);
    const [tags, setTags] = useState([]);
    const [checkStar, setCheckStar] = useState(false);
    const [time, setTime] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [statusDocument, setStatusDocument] = useState("");
    const [loading, setLoading] = useState(false);
    const [contentName, setContentName] = useState("");
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [showSnackBarDocRemove, setShowSnackBarDocRemove] = useState(false);
    const fileInputRef = useRef();
    const textareaRef = useRef();
    const token = localStorage.getItem("token");
    const selectRefTribunal = useRef(null)
    const selectRefCategories = useRef(null);
    const document = "modification reussie"
    const userIdDocument = localStorage.getItem("IdDocumentsUser")
    const role = localStorage.getItem("role")
    const grasText = () => {
        document.execCommand('bold', false, null);
    };

    const underlineText = () => {
        document.execCommand('underline', false, null);
    };

    const italiqueText = () => {
        document.execCommand('italic', false, null);
    };

    const linkText = () => {
        const url = prompt("Entrez l'URL du lien:");
        if (url) {
            document.execCommand('createLink', false, url);
        }
    };

    const listText = () => {
        document.execCommand('insertUnorderedList', false, null);
    };

    const changeFontSize = (size) => {
        document.execCommand('fontSize', false, size);
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                if (textareaRef.current) {
                    const div = document.createElement('div');
                    div.innerHTML = `<a href="${fileContent}" target="_blank" rel="noopener noreferrer">${file.name}</a>`;

                    textareaRef.current.appendChild(div);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    function fetchDataTribunal(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                //console.log(result?.result?.data)
                setDataTribunal(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        const urlTribunal = "courts?sorts%5Bcourts.created_at%5D=desc"
        fetchDataTribunal(urlTribunal)
    }, [])
    function fetchDataCategories(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                console.log(result?.result?.data)
                setDataCategories(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        const urlCategories = "categories/list"
        fetchDataCategories(urlCategories)
    }, [])
    const handleSelectTribunal = () => {
        let select = selectRefTribunal.current
        setRotateIconTribunal(!rotateIconTribunal);
        if (rotateIconTribunal) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleTribunal(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleTribunal(true);
        }
    }
    const handleClickTribunal = (value, id) => {
        console.log(value)
        const select = selectRefTribunal.current
        setTribunalName(value)
        setOptionVisibleTribunal(false);
        setTribunalId(id);
        //select.style.borderBottomRightRadius = "10px";
        //select.style.borderBottomLeftRadius = "10px";
    }
    const handleSelectCategories = () => {
        let select = selectRefCategories.current
        setRotateIconCategories(!rotateIconCategories);
        if (rotateIconCategories) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleCategories(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleCategories(true);
        }
    }
    const handleClickCategories = (value, id) => {
        console.log(value)
        const select = selectRefCategories.current
        setCategoryName(value)
        setOptionVisibleCategories(false);
        setCategoriesId(id);
        //  select.style.borderBottomRightRadius = "10px";
        //  select.style.borderBottomLeftRadius = "10px";
    }
    function fetchDataDocumentList(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setTribunalId(result?.result?.data.courtId)
                setCategoriesId(result?.result?.data.categoryId)
                setTribunalName(result?.result?.data.court.name)
                setCategoryName(result?.result?.data.category.name)
                setJugementDate(result?.result?.data.judgementDate)
                setJugementNumber(result?.result?.data.judgementNumber)
                setStatusDocument(result?.result?.data.status)
                setCheckStar(result?.result?.data.isFavourite)
                setTime(result?.result?.data.createdAt)
                setName(result?.result?.data.user.lastName)
                setContentName(result?.result?.data.metaData.content)
                setDescription(result?.result?.data.description)
                setAuthors(JSON.parse(result?.result?.data.authors))
                setTags(JSON.parse(result?.result?.data.tags))
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        const urlDocument = `files/${id}`
        fetchDataDocumentList(urlDocument)
    }, [])

    const handleAddAuthors = (inputValue) => {
        const newChips = inputValue.split(',')
            .map(word => word.trim())
            .filter(word => word.length > 0 && !authors.includes(word));

        setAuthors(prevChips => [...prevChips, ...newChips]);
    };
    const handleRemoveAuthors = (indexToRemove) => {
        setAuthors(prevChips => prevChips.filter((chip, index) => index !== indexToRemove));
    };
    const handleAddChip = (inputValue) => {
        const newChips = inputValue.split(',')
            .map(word => word.trim())
            .filter(word => word.length > 0 && !tags.includes(word));

        setTags(prevChips => [...prevChips, ...newChips]);
    };
    const handleRemoveChip = (indexToRemove) => {
        setTags(prevChips => prevChips.filter((chip, index) => index !== indexToRemove));
    };
    const openStar = () => {
        setCheckStar(true);
    };
    const closeStar = () => {
        setCheckStar(false);
      
    };
    const saveDocument = async () => {

        let data = {
            isFavourite: checkStar,
            content: contentName,
            courtId: tribunalId,
            categoryId: categoriesId,
            jugementNumber: jugementNumber,
            jugementDate: jugementDate,
            authors: authors,
            description: description,
            tags: tags,
            status: "approved"
        }
        console.log(data)
        try {
            setLoading(true);
            const result = await fetchData(`files/${id}`, "PUT", data, token)
            console.log(result)
            if (result.status === 200) {
                setShowSnackBar(true);

                setTimeout(() => {
                    fetchData(
                        "files?sorts%5Bfiles.created_at%5D=desc&page=1",
                        "GET",
                        null,
                        token
                    ).then((response) => {
                        console.log(response);
                        localStorage.setItem("document", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
                        backDocument()
                    });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }


    }


    const updatePdf = async (id) => {
        const url = `https://staging.api.data-lex.net/files/${id}/download`;

        const headers = {
            'Accept': 'application/octet-stream',
            'Authorization': `Bearer ${token}`,
        };

        try {
            let response = await fetch(url, { headers, method: 'GET' });

            if (!response.ok) {
                return console.log('Erreur lors du téléchargement du fichier');
            }

            const blob = await response.blob();
            saveAs(blob, 'document.pdf');
        } catch (error) {
            console.error('Erreur:', error);
        }
    };

    const handleInputFrench = (e) => {
        let newName = e.target.innerText;
         setContentName(newName);
      };
      const handleRemoveDocumentsUser = () => {
        fetchData(`files/${userIdDocument}`, "Delete", null, token).then((response) => {
          console.log(response)
          if (response.status === 204) {
             setShowSnackBarDocRemove(true);
            setTimeout(() => {
                fetchData(
                    "files?sorts%5Bfiles.created_at%5D=desc&page=1",
                    "GET",
                    null,
                    token
                ).then((response) => {
                    console.log(response);
                    localStorage.setItem("document", JSON.stringify(response));
                    window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
                    backDocument()
                });
            }, 2000);
    
          }
    
        }).catch((error) => {
          console.log(error)
          
        })
      }



    return (
        <div className="main_accueil">
            <div className='header_document_details'>
                <img src={buttonBack} alt="buttonBack" onClick={backDocument} />
                <h2 className='header_document_details_h2'>Document {id}</h2>
                {statusDocument === "pending" ? <span className='button_status_document'>En Attente</span> : <span className='button_status_document1'>Validé</span>}
            </div>
            <div className='details_documentsdetails_documents'>
                <div className='details_documents_header'>
                    <h3 className='details_documents_header_h3'>Details du document</h3>
                    <span className='details_documents_header_span'>Envoyé le {formatDate(time)} par {name}</span>
                </div>
                <div className='parent_form_children'>
                    <div className="children_form" onClick={handleSelectTribunal} ref={selectRefTribunal}>
                        <label htmlFor="">Tribunal</label>
                        <input type="text" placeholder="Haute Instance" value={tribunalName} title={tribunalName} />
                        <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconTribunal && "rotate(180deg)" }} />
                        {optionVisibleTribunal && (
                            <div className="optionCity">
                                {
                                    dataTribunal?.map((element) => {
                                        return (
                                            <span key={element.id} onClick={() => handleClickTribunal(element.name, element.id)} >
                                                {element.name}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                    <div className="children_form" onClick={handleSelectCategories} ref={selectRefCategories}>
                        <label htmlFor="">Categories</label>
                        <input type="text" placeholder="Haute Instance" value={categoryName} title={categoryName} />
                        <img src={downForm} alt="" className="down_form" />
                        {optionVisibleCategories && (
                            <div className="optionCountryCity">

                                {
                                    dataCategories?.map((element) => {
                                        return (
                                            <div className="countriesCity" key={element.id}>
                                                <span className="countriesCity_span">{element.name}</span>
                                                {
                                                    element.subCategories.map((element) => {
                                                        return (
                                                            <div className="AllcountriesCity" key={element.id}>
                                                                <span className="AllcountriesCity_span" onClick={() => handleClickCategories(element.name, element.id)} >
                                                                    {element.name}
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        )}
                        {

                        }
                    </div>
                    <div className="children_form">
                        <label htmlFor="">Tags</label>
                        <input type="text" placeholder="Haute Instance" onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddChip(e.target.value);
                                e.target.value = '';
                            }
                        }} />
                        <div className="option_chips_name">
                            {tags?.map((chip, index) => (
                                <div key={index} className="chip">
                                    <span>{chip}</span>
                                    <img src={closeChips} className="close_chips" onClick={() => handleRemoveChip(index)} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="children_form">
                        <label htmlFor="">Date de jugement</label>
                        <input type="text" placeholder="Haute Instance" value={jugementDate} />
                    </div>
                    <div className="children_form">
                        <label htmlFor="">N jugement</label>
                        <input type="text" placeholder="Haute Instance" value={jugementNumber} onChange={(e) => setJugementNumber(e.target.value)} />
                    </div>
                    <div className="children_form">
                        <label htmlFor="">Auteurs</label>
                        <input type="text" placeholder="Haute Instance" onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddAuthors(e.target.value);
                                e.target.value = '';
                            }
                        }} />
                        <div className="option_chips_name2">
                            {authors?.map((chip, index) => (
                                <div key={index} className="chip">
                                    <span>{chip}</span>
                                    <img src={closeChips} className="close_chips" onClick={() => handleRemoveAuthors(index)} />
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                <div className="parent_textarea textarea_document">
                    <label htmlFor="">Description</label>
                    <textarea placeholder="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </div>
            <div className='details_pdf'>
                <span className='details_documents_header_h3'>Document Correspondant</span>
                <div className='sous_details_file' onClick={() => updatePdf(id)}>
                    <img src={file} alt="file" />
                    <span>document.pdf</span>
                </div>
            </div>
            <div className="transcription">
                <label htmlFor="reponse">Transcription</label>
                <div className="sous_description_transcription">
                    <div className="sous_description_img_transcription">
                        <img src={gras} alt="" className="img_profession" onClick={grasText} />
                        <img src={underline} alt="" className="img_profession" onClick={underlineText} />
                        <img src={italique} alt="" className="img_profession" onClick={italiqueText} />
                        <img src={police} alt="" className="img_profession" onClick={() => changeFontSize(4)} />
                        <img src={link} alt="" className="img_profession link" onClick={linkText} />
                        <img src={upload} alt="" className="img_answer_profession upload" onClick={handleUploadClick} />
                        <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <img src={list} alt="" className="img_profession list" onClick={listText} />
                    </div>
                    <div
                        contentEditable
                        ref={textareaRef}
                        onBlur={(e) => handleInputFrench(e)}

                        className="textarea_transcription"
                    >{contentName}</div>
                </div>
            </div>
            <div className='favoris_document'>
                <span></span>
                <div className='sous_favoris_document'>
                    {checkStar ? <img src={starYellow} alt="" onClick={closeStar} /> : <img src={star} alt="" onClick={openStar} />}
                    <span>Favoris</span>
                </div>

            </div>
            {role === "administrator" && <div className='sous_favoris_parent_button'>
                <span></span>
                {statusDocument === "pending" ? <div className='sous_favoris_button'>
                    {loading ? <div className='sous_favoris_button_1' onClick={saveDocument}>
                        <span>En cours ...</span>
                        <img src={checkNikes} alt="checkNikes" />
                    </div> : <div className='sous_favoris_button_1' onClick={saveDocument}>
                        <span>Valider</span>
                        <img src={checkNikes} alt="checkNikes" />
                    </div>}
                    <div className='sous_favoris_button_2' onClick={handleRemoveDocumentsUser}>
                        <span>Rejeter</span>
                        <img src={reject} alt="reject" />
                    </div>
                </div> : <div className='sous_favoris_button_1' onClick={saveDocument}>
                    <span>Enregistrer</span>
                    <img src={checkNikes} alt="checkNikes" />
                </div>}
            </div>}
            {showSnackBar && (
                <SnackBar
                    message="Document créé avec succès"
                    image={circleNike}
                    timeToHide={3000}
                    onClose={() => setShowSnackBar(false)} // Ferme la snackbar après l'expiration du temps
                />
            )}
            {showSnackBarDocRemove && (
                <SnackBar
                    message="Document rejeté avec succès"
                    image={circleNike}
                    timeToHide={3000}
                    onClose={() => setShowSnackBar(false)} // Ferme la snackbar après l'expiration du temps
                />
            )}

        </div>
    )
}