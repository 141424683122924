import { MainHeader } from "../../repeatableComponents/atomes/mainHeader/MainHeader";
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import { useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import circleNike from "../../../assets/icons/circleNike.png"
import { UserTableAdmin } from "../../repeatableComponents/Table5/UserTableAdmin";
import { MainHeaderAmin } from "../../repeatableComponents/atomes/mainHeader/mainHeaderAdmin";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import { fetchData } from "../../../assets/helpers/fetchData";

export const MainAdmin = () => {
    const [etat, setEtat] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [idAdmin, setIdAdmin] = useState("");
    const [nameAdmin, setNameAdmin] = useState("");
    const [lastNameAdmin, setLastNameAdmin] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const masque= ()=> {
       return setEtat(true)
    }
    const closePopup = ()=>{
        setEtat(false)
        setOpenEyes(false)
    }
    const openDocument = (id,name,secondName,email) => {
        setEtat(true);
        setOpenEyes(true);
        setIdAdmin(id)
       setNameAdmin(name)
       setLastNameAdmin(secondName)
       setEmail(email)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = [
            { name: 'Nom', value: nameAdmin },
            { name: 'prenom', value: lastNameAdmin },
            { name: 'email', value: email },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const dataInfosAdmin = {
            firstName:nameAdmin,
            lastName: lastNameAdmin,
           email:email,
           role:"administrator"
        }
       
        try {

            setLoading(true);
            const result = await fetchData("users", "POST", dataInfosAdmin, token)
            if (result.status === 200) {
                 snackbbar(document.querySelector("#body"), circleNike,"administrateur crée avec succés", 2000);
                 setTimeout(() => {
                    fetchData(
                        "users?roles=administrator&sorts%5Busers.first_name%5D=asc",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("admin", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedAdmins"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    }
    const handleInput = async (e) =>{
        e.preventDefault();
        const fields = [
            { name: 'Nom', value: nameAdmin },
            { name: 'prenom', value: lastNameAdmin },
            { name: 'email', value: email },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const dataInfosAdmin = {
            firstName:nameAdmin,
            lastName: lastNameAdmin,
            email: email,
            role:"administrator"
    
        }
        try {

            setLoading(true);
            const result = await fetchData(`users/${idAdmin}`, "PUT", dataInfosAdmin, token)
            console.log(result)
            if (result.status === 200) {
                
                 snackbbar(document.querySelector("#body"), circleNike, "modification reussie", 2000);
                 setTimeout(() => {
                    fetchData(
                        "users?roles=administrator&sorts%5Busers.first_name%5D=asc",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("admin", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedAdmins"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }

    }
    return (
        <div className="main_accueil">
             {etat &&<div className="popup_wrapper">
             <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                {etat && openEyes ? (
                        <HeaderForm
                            text={`Administrateur ${idAdmin}`}
                            closePopup={closePopup} />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouvelle Administrateur"
                                closePopup={closePopup} />
                        )
                    )}
                <div className="children_form">
                    <label htmlFor="">Noms</label>
                    <input type="text" placeholder="Arthur" value={nameAdmin} onChange={(e)=>setNameAdmin(e.target.value)}/>
                </div>
                <div className="children_form">
                    <label htmlFor="">Prenoms</label>
                    <input type="text" placeholder="arthur" value={lastNameAdmin} onChange={(e)=>setLastNameAdmin(e.target.value)}/>
                </div>
                <div className="children_form">
                    <label htmlFor="">Adresse mail</label>
                    <input type="email" placeholder="ricky@gmail.com" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </div>
              
                <div className="parent_button_tribunal">
                    <span></span>
                    {/* <span className="button_tribunal">Ajouter l'administrateur</span> */}
                    {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal" onClick = {handleInput}>Enregistrer les modifications</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter l'administrateur</button>
                                )}
                            </>
                        )}
                </div>
                

            </form>
            </div>}
            <MainHeaderAmin text="Administrateur"/>
                <UpdateTribunal masque={masque} text="+ Ajouter un administrateur"/>  
            <UserTableAdmin t1="Identifiant" t2="Noms" t3="Prenoms" t4="Adresse Mail" t5="Actions" openDocument={openDocument}/>
           

        </div>
    )
}