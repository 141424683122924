
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './checkDate.css'; // Votre CSS personnalisé

export const CheckDate = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  /***filtrer les elemnts qui sont dans mon intervalle de date */
  let data = JSON.parse(localStorage.getItem("document"));
  console.log(data)
   // Fonction pour filtrer les données par dates
   const filterDataByDate = () => {
    const result = {
      ...data,
      result: {
        ...data.result,
        data: data.result.data.filter((item) => {
          const itemDate = new Date(item.judgementDate);
          return itemDate >= startDate && itemDate <= endDate;
        })
      }
    };
    setFilteredData(result); // Met à jour l'état avec l'objet filtré
    localStorage.setItem("document", JSON.stringify(result)); // Mise à jour du localStorage avec l'objet filtré
    window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
  };

  useEffect(() => {
    if (startDate && endDate) {
      filterDataByDate();
    }
  }, [startDate, endDate]);

//   const filterDataByDate = () => {
//     if (!startDate || !endDate) return data?.result?.data; // Si l'intervalle n'est pas défini, retourne toutes les données

//     const c = data?.result?.data.filter((item) => {
//       const itemDate = new Date(item.judgementDate);
//       console.log(itemDate)
//       return itemDate >= startDate && itemDate <= endDate;
//     });
//     console.log(c)
//     setArray(c)
//   };
  

 
//   useEffect(()=>{
    
//     filterDataByDate();

//   },[startDate,endDate])
//   useEffect(() => {
//     if (array?.length > 0) {
//       localStorage.setItem("document", JSON.stringify(array));
//       window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
//     }
//   }, [array]);
//   // Fonction pour gérer la modification manuelle dans les inputs
  const handleStartDateChange = (e) => {
    const date = new Date(e.target.value);
    if (!isNaN(date.getTime())) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (e) => {
    const date = new Date(e.target.value);
    if (!isNaN(date.getTime())) {
      setEndDate(date);
    }
  };

  // Fonction pour vider le champ de début de date
  const clearStartDate = () => {
    setStartDate(null);
  };

  // Fonction pour vider le champ de fin de date
  const clearEndDate = () => {
    setEndDate(null);
  };

  return (
    <div className="date-range-container">
      {/* Barre de recherche pour la date de début et la date de fin */}
      <div className="input-container">
        <div className="input-wrapper">
          <input
            type="date"
            className="date-input"
            placeholder="Choisir la date de début"
            value={startDate ? startDate.toISOString().split('T')[0] : ''}
            onChange={handleStartDateChange}
          />
          {startDate && (
            <button className="clear-btn" onClick={clearStartDate}>
              ×
            </button>
          )}
        </div>

        <div className="input-wrapper">
          <input
            type="date"
            className="date-input"
            placeholder="Choisir la date de fin"
            value={endDate ? endDate.toISOString().split('T')[0] : ''}
            onChange={handleEndDateChange}
          />
          {endDate && (
            <button className="clear-btn" onClick={clearEndDate}>
              ×
            </button>
          )}
        </div>
      </div>

      {/* Sélecteur de plage de dates avec les deux mois */}
      <DatePicker
        selected={startDate}
        onChange={(dates) => {
          const [start, end] = dates;
          setStartDate(start);
          setEndDate(end);
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        monthsShown={2}
        dateFormat="yyyy-MM-dd"
      />
    </div>
  );
};
