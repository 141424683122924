
import React, { useState, useEffect, useRef } from 'react';
import "./graph.css";
import downForm from "../../assets/icons/downForm.png"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { fetchData } from '../../assets/helpers/fetchData';

export const StatisticsGraph = () => {
    const Data = [
        { month: 'Janvier', total: 0 },
        { month: 'Février', total: 150 },
        { month: 'Mars', total: 148 },
        { month: 'Avril', total: 450 },
        { month: 'Mai', total: 200 },
        { month: 'Juin', total: 220 },
        { month: 'Juillet', total: 300 },
        { month: 'Août', total: 230 },
        { month: 'Septembre', total: 320 },
        { month: 'Octobre', total: 240 },
        { month: 'Novembre', total: 280 },
        { month: 'Décembre', total: 550 },
      ];
  const [noData, setNoData] = useState(false);
  const [rotateIcon, setRotateIcon] = useState(false);
    const [optionVisible, setOptionVisible] = useState(false);
    const [initialData, setInitialData] = useState(Data);
    const [optionName, setOptionName] = useState("2019");
    const [isYearSelected, setIsYearSelected] = useState(false);
    const selectRef = useRef()
    const token = localStorage.getItem('token')   
    const dataYears= ["2024","2023","2022","2021","2020"] 
    const monthNames = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ];
    
    const handleSelect = () => {
        let select = selectRef.current
        setRotateIcon(!rotateIcon);
        if (rotateIcon) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisible(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisible(true);
        }
    }
    const handleClick = (value) => {
        const select = selectRef.current
        setOptionName(value)
        setOptionVisible(false);
        setIsYearSelected(true)
            select.style.borderBottomRightRadius = "10px";
            select.style.borderBottomLeftRadius = "10px";
    }
    function fetchDataForYear(url) {
        fetchData(url, "GET", null, token)
          .then((result) => {
            const dataGraph = result?.result?.data
            const transformedData = dataGraph?.map(item => ({
                ...item,
                month: monthNames[item.month - 1] // Convertir 1,2,3 en 'Janvier', 'Février', 'Mars', etc.
              }));
            setInitialData(transformedData);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    
      useEffect(() => {
         if (isYearSelected) {
          const url = `chart?year=${optionName}`;
          fetchDataForYear(url);
         }
      }, [optionName,isYearSelected]);

  return (
    <div className='graph'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 className='graph_h1'>Statistiques</h3>
        <div className="children_graph" onClick={handleSelect} ref={selectRef}>
                        <input type="text" placeholder="2024" value={optionName} onChange={(e) => setOptionName(e.target.value)}/>
                        <img src={downForm} alt="" className="down_form_graph" style={{ transform: rotateIcon && "rotate(180deg)" }} />
                        {optionVisible && (
                            <div className="optionGraph">
                                {
                                    dataYears?.map((element,index) => {
                                        return (
                                            <span key={index} onClick={() => handleClick(element)} >
                                                {element}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
      </div>
      
      <ResponsiveContainer width="100%" height={400}>
        {noData ? (
          <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px', color: '#8884d8' }}>
            Pas de documents pour cette année
          </div>
        ) : (
          <AreaChart data={initialData}>
             <defs>
              <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#e8ebf7" />
                <stop offset="95%" stopColor="#e8ebf7"/>
              </linearGradient>
            </defs> 
     
            
            {/* Grille : seulement des lignes horizontales */}
            <CartesianGrid vertical={false} strokeDasharray="0.1"  stroke= 'gray' />
            
            {/* Axe des abscisses : décale les mois vers le bas avec dy */}
            <XAxis dataKey="month" tick={{ dy: 15, fontSize: 12,fill: 'gray' }}
              axisLine={{ stroke: 'gray', strokeWidth: 0.5 }}
            />
            
            {/* Axe des ordonnées : montrer seulement les valeurs sans ligne verticale */}
            <YAxis 
              axisLine={false}  // Supprime la ligne de l'axe Y
              tickLine={false}  // Supprime les petites barres de l'axe Y
              tick={{ dx: -5,fontSize: 12,fill: '#2b3034' }} // Ajoute un peu d'espace entre les labels et les lignes
            />
            
            <Tooltip 
              contentStyle={{ borderRadius: '5px', backgroundColor: '#ffffff', border: 'none' }}
              labelStyle={{ color: '#2b3034', fontSize: '12px' }} // Style pour le label du mois
              itemStyle={{ color: '#8884d8', fontSize: '14px' }}  // Style pour le total
            />
            
            {/* Courbe avec points affichés */}
             <Area 
              type="monotone" 
              dataKey="total" 
              stroke="#8884d8" 
              fill="url(#colorTotal)" 
              dot={true}  
              activeDot={{ r: 8 }} 
            /> 
           
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

