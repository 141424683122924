import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import "./sideBar.css"
import jury from "../../../assets/logos/jury.png"
import Home from "../../../assets/icons/Home.png"
import bibliotheque from "../../../assets/icons/bibliotheque.png"
import down from "../../../assets/icons/down.png"
import people from "../../../assets/icons/people.png"
import Vector from "../../../assets/icons/Vector.png";
import HomeBlack from "../../../assets/icons/HomeBlack.png"
import bibliothequeOrange from "../../../assets/icons/bibliothequeOrange.png";
import aideReader from "../../../assets/icons/aideReader.png";
import utilisateur from "../../../assets/icons/utilisateur.png";
import localisation from "../../../assets/icons/localisation.png";
import { LeftNavigation } from '../../repeatableComponents/atomes/LeftNavigation';
import { useState } from 'react';

export const SideBarEditor = () => {
    return (
        <div className="left">
            <img src={jury} alt="" className='left_logos'/>
            <div className='left_navigation'>
                <div className='left_navigation_top'>
                    <span className='accueil_page'>Accueil</span>
                    <NavLink to="/editor" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                        {({ isActive }) => (
                            <div className={isActive ? "left_navigation_top_child2" : "left_navigation_top_child2_black"}>
                                <img src={isActive ? Home : HomeBlack} alt="" />
                                <span className='dasboard'>Tableau de Bord</span>
                            </div>
                        )}
                    </NavLink>
                </div>
                <div className='left_navigation_bottom'>
                    <span className='accueil_page'>Pages</span>
                    <div className='left_navigation_bottom_child2'>
                        <div className='left_navigation_bottom_child2__child1'>
                            <NavLink to="/editor/bibliotheques" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <div className='parent_biblio'>
                                        <img src={isActive ? bibliothequeOrange : bibliotheque} alt="" />
                                        <span className={isActive ? "left_navigation_bottom_span_nav" : "left_navigation_bottom_span_nav_link"}>Bibliothèque</span>
                                    </div>
                                )}
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='left_navigation_bottom_child2'>
                    <div className='left_navigation_bottom_child2__child1'>
                        <NavLink to="/editor/user" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                            {({ isActive }) => (
                                <div className='parent_biblio'>
                                    <img src={isActive ? utilisateur : people} alt="" />
                                    <span className={isActive ? "left_navigation_bottom_span_nav" : "left_navigation_bottom_span_nav_link"}>Utilisateurs</span>
                                </div>
                            )}
                        </NavLink>

                    </div>
                </div>
                <div className='left_navigation_bottom_child2__child1'>
                    <NavLink to="/editor/faq" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                        {({ isActive }) => (
                            <div className='parent_biblio'>
                                <img src={isActive ? aideReader : localisation} alt="" />
                                <span className={isActive ? "left_navigation_bottom_span_nav" : "left_navigation_bottom_span_nav_link"}>Aide</span>
                            </div>
                        )}
                    </NavLink>

                </div>
            </div>
        </div>
    )
}