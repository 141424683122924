import { useState } from "react"
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm"
import { MainHeader } from "../../repeatableComponents/atomes/mainHeader/MainHeader"
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal"
import "../mainTribunaux/mainTribunaux.css"
import {  UserTableTypesTribunal } from "../../repeatableComponents/Table5/UserTableTypesTribunal"
import { MainHeaderTypes } from "../../repeatableComponents/atomes/mainHeader/MainHeaderTypes"
import { fetchData } from "../../../assets/helpers/fetchData"
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar"
import circleNike from "../../../assets/icons/circleNike.png"

export const MainTypes = () => {
    const [etat, setEtat] = useState(false);
    const [nameType,setNameType] = useState("");
    const [nameDescription,setNameDescription] = useState("");
    const [openEyes, setOpenEyes] = useState(false);
    const [loading,setLoading] = useState(false);
    const [userTypeId,setUserTypeId] = useState("");
    const token = localStorage.getItem("token")
    const typeTribunal = " Type creé avec succés"
    const masque= ()=> {
        return setEtat(true)
     }
    const closePopup = ()=>{
        setEtat(false);
        setOpenEyes(false);
        setNameType("")
    }
    const openDocument = (id,name) => {
        fetchData(`court-types/${id}`, "GET", null, token)
        .then((result) => {
            console.log(result)
        })
        .catch((error) => {
            console.log(error);
        });
        setUserTypeId(id)
        setNameType(name)
        setEtat(true);
        setOpenEyes(true);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = [
            { name: 'Nom du Type', value: nameType },
            { name: 'description du type', value: nameDescription },
           
        ];

        for (let i = 0; i < data.length; i++) {
            if (!data[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${data[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const datas ={
            name:nameType,
            description:nameDescription
        }
        try {

            setLoading(true);
            const result = await fetchData("court-types", "POST", datas, token)
            console.log(result)
            if (result.status === 201) {
                 snackbbar(document.querySelector("#body"), circleNike, typeTribunal, 2000); 
                 setTimeout(() => {
                    fetchData(
                        "court-types?sorts%5Bcreated_at%5D=desc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        console.log(response);
                        localStorage.setItem("resultTypes", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedTypes"));
                        setEtat(false);
                        setNameType("")
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main_accueil">
            {etat &&<div className="popup_wrapper">
            <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                {etat && openEyes ? (
                        <HeaderForm
                            text={`Type de Tribunal ${userTypeId}`}
                            closePopup={closePopup}
                        />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouveau Type"
                                closePopup={closePopup}
                            />
                        )
                    )}
                <div className="children_form">
                    <label htmlFor="">Nom du Type</label>
                    <input type="text" placeholder="Nom du Type" value={nameType} onChange={(e) => setNameType(e.target.value)}/>
                </div>
                <div className="parent_textarea">
                    <label htmlFor="">Description</label>
                    <textarea  placeholder="Description"  value={nameDescription} onChange={(e) => setNameDescription(e.target.value)}/>
                </div>
                <div className="parent_button_tribunal">
                    <span></span>
                    {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                   <span></span>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter Un Tribunal</button>
                                )}
                            </>
                        )}
                </div>
                

            </form>
            </div>}
             <MainHeaderTypes text="Types de Tribunal"/>
             <div className="all_filter">
                <UpdateTribunal masque={masque} text ="+ Ajouter un nouveau type"/>
            </div>
            <UserTableTypesTribunal t1="Identifiant" t2="Nom du type" t3="Action" openDocument={openDocument}/>

        </div>
        )
    }