
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import "./sideBar.css"
import jury from "../../../assets/logos/jury.png"
import Home from "../../../assets/icons/Home.png"
import bibliotheque from "../../../assets/icons/bibliotheque.png"
import down from "../../../assets/icons/down.png"
import people from "../../../assets/icons/people.png"
import Vector from "../../../assets/icons/Vector.png"
import HomeBlack from "../../../assets/icons/HomeBlack.png"
import localisation from "../../../assets/icons/localisation.png"
import { LeftNavigation } from '../../repeatableComponents/atomes/LeftNavigation';
import { useState } from 'react';

export const SideBar = () => {
    const [openedIndex, setOpenedIndex] = useState(-1);

    const toggleOpen = (index) => {
        setOpenedIndex(openedIndex === index ? -1 : index);
    };

    return (
        <div className="left">
            <img src={jury} alt="" className='left_logos' />
            <div className='left_navigation'>
                <div className='left_navigation_top'>
                    <span className='accueil_page'>Accueil</span>
                    <NavLink to="/home" className="nav_link_home">
                        {({ isActive }) => (
                            <div className={isActive ? "left_navigation_top_child2" : "left_navigation_top_child2_black"}>
                                <img src={isActive ? Home : HomeBlack} alt="" />
                                <span className='dasboard'>Tableau de Bord</span>
                            </div>
                        )}
                    </NavLink>
                </div>
                <div className='left_navigation_bottom'>
                    <span className='accueil_page'>Pages</span>
                    <div className='left_navigation_bottom_child2' onClick={() => toggleOpen(0)}>
                        <div className='left_navigation_bottom_child2__child1'>
                            <img src={Vector} alt="" />
                            <span className='left_navigation_bottom_span'>Judiciare</span>
                        </div>
                        <img src={down} alt="" style={{ transform: openedIndex === 0 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </div>
                    {openedIndex === 0 && (
                        <div className='open_pages'>
                            <NavLink to="/tribunaux" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Tribunaux</span>
                                )}
                            </NavLink>
                            <NavLink to="/types" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Types de Tribunal</span>
                                )}
                            </NavLink>
                            <NavLink to="/juridictions" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Juridictions</span>
                                )}
                            </NavLink>
                        </div>
                    )}
                    <div className='left_navigation_bottom_child2' onClick={() => toggleOpen(1)}>
                        <div className='left_navigation_bottom_child2__child1'>
                            <img src={bibliotheque} alt="" />
                            <span className='left_navigation_bottom_span'>Bibliothèque</span>
                        </div>
                        <img src={down} alt="" style={{ transform: openedIndex === 1 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </div>
                    {openedIndex === 1 && (
                        <div className='open_pages'>
                            <NavLink to="/categories" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Categories</span>
                                )}
                            </NavLink>
                            <NavLink to="/document" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Document</span>
                                )}
                            </NavLink>
                        </div>
                    )}
                    <div className='left_navigation_bottom_child2' onClick={() => toggleOpen(2)}>
                        <div className='left_navigation_bottom_child2__child1'>
                            <img src={people} alt="" />
                            <span className='left_navigation_bottom_span'>Utilisateurs</span>
                        </div>
                        <img src={down} alt="" style={{ transform: openedIndex === 2 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </div>
                    {openedIndex === 2 && (
                        <div className='open_pages'>
                            <NavLink to="/admin" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Administrateurs</span>
                                )}
                            </NavLink>
                            <NavLink to="/actor" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Acteurs judiciaires</span>
                                )}
                            </NavLink>
                        </div>
                    )}
                    <div className='left_navigation_bottom_child2' onClick={() => toggleOpen(3)}>
                        <div className='left_navigation_bottom_child2__child1'>
                            <img src={localisation} alt="" />
                            <span className='left_navigation_bottom_span'>Zone géographique</span>
                        </div>
                        <img src={down} alt="" style={{ transform: openedIndex === 3 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </div>
                    {openedIndex === 3 && (
                        <div className='open_pages'>
                            <NavLink to="/countries" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Pays</span>
                                )}
                            </NavLink>
                            <NavLink to="/city" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Ville</span>
                                )}
                            </NavLink>
                        </div>
                    )}
                    <div className='left_navigation_bottom_child2' onClick={() => toggleOpen(4)}>
                        <div className='left_navigation_bottom_child2__child1'>
                            <img src={localisation} alt="" />
                            <span className='left_navigation_bottom_span'>Centre d'aide</span>
                        </div>
                        <img src={down} alt="" style={{ transform: openedIndex === 4 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </div>
                    {openedIndex === 4 && (
                        <div className='open_pages'>
                            <NavLink to="/faqs" className={({ isActive }) => isActive ? "nav_link" : "nav_link_none"}>
                                {({ isActive }) => (
                                    <span className={isActive ? "nav_link" : "nav_link_none"}>Faqs</span>
                                )}
                            </NavLink>
                           
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}