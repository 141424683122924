import './table.css';
import eyes from "../../../assets/icons/eyes.png";
import remover from "../../../assets/icons/remover.png";
import rightCategorie from "../../../assets/icons/rightCategorie.png";
import { useEffect, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { Pagination } from '../atomes/pagination/Pagination';
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';
import circleNike from "../../../assets/icons/circleNike.png";
import close from "../../../assets/icons/close.png"
import rafiki from "../../../assets/logos/rafiki.png"
import { RemovePopup } from './RemovePopup';

export const UserTableCategories = ({ t1, t2, t3, t4, t5, openDocument }) => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [isError, setIsError] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [userId, setUserId] = useState("");
  const token = localStorage.getItem("token");
  const messageRemove = "suppression reussie"
  const notRemove = "vous ne pouvez pas supprimer une categorie ayant des documents"
  



  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleSousCategories = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  


  const handleRemoveSousCategories = (index, i) => {
    const initialUrl = "categories?sorts%5Bcategories.created_at%5D=desc&page=1";
    fetchData(`categories/${index}`,"Delete",null,token).then((response) =>{
      if(response.status === 204){
          snackbbar(document.querySelector("#body"), circleNike, messageRemove, 2000);
          const updatedCategories = [...users];
      updatedCategories[index].subCategories.splice(i, 1);
      setUsers(updatedCategories);
      setTimeout(() => {
        fetchDataByUrl(initialUrl);
      }, 3000);
      }
  
    }).catch((error) =>{
      console.log(error)
      if(error.status === 400){
        snackbbar(document.querySelector("#body"),circleNike,notRemove, 2000);
      }
    })
  };
  const fetchDataByUrl = (url) => {
    fetchData(url, "GET", null, token)
      .then((result) => {
        console.log(result)
        const data = result?.result?.data;
        const meta = result?.result?.meta;
        setUsers(data);
        setTotalPages(meta?.lastPage || 1);
        setNextPageUrl(meta?.nextPageUrl || null);
        setPrevPageUrl(meta?.previousPageUrl || null);
        setCurrentPage(meta?.currentPage || 1);
      })
      .catch((error) => {
        console.error("Erreur de connexion :", error);
        if (error.message === "Failed to fetch") {
          return setIsError(true)
        }
      });
  };
  const loadLocalStorageData = () => {
    const data = JSON.parse(localStorage.getItem("resultCategories"));
    if (data) {
      console.log(data);
      const meta = data?.result?.meta;
      setUsers(data?.result?.data);
      setTotalPages(meta?.lastPage || 1);
      setNextPageUrl(meta?.nextPageUrl || null);
      setPrevPageUrl(meta?.previousPageUrl || null);
      setCurrentPage(meta?.currentPage || 1);
      setIsError(false);
    }

  };
  useEffect(() => {
    const initialUrl = "categories?sorts%5Bcategories.created_at%5D=desc&page=1";
    fetchDataByUrl(initialUrl);
    // Écouter l'événement en temps reel du tableau contenu dans mon composant de MainHeader
    const handleLocalStorageUpdate = () => {
      loadLocalStorageData();
    };

    window.addEventListener("localStorageUpdatedCategories", handleLocalStorageUpdate);

    // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener("localStorageUpdatedCategories", handleLocalStorageUpdate);
    };
  }, []);
  const handleNextClick = () => {
    if (nextPageUrl) {
      fetchDataByUrl(nextPageUrl);
    }
  };

  const handlePrevClick = () => {
    if (prevPageUrl) {
      fetchDataByUrl(prevPageUrl);
    }
  };

  const handlePageClick = (pageNumber) => {
    const specificPageUrl = `categories?sorts%5Bcategories.created_at%5D=desc&page=${pageNumber}`;
    fetchDataByUrl(specificPageUrl);
  };
  const handleRemoveCategories = () => {
    const initialUrl = "categories?sorts%5Bcategories.created_at%5D=desc&page=1";
    fetchData(`categories/${userId}`, "Delete", null, token).then((response) => {
      if (response.status === 204) {
        snackbbar(document.querySelector("#body"), circleNike, messageRemove, 2000);
        setOpenRemove(false);
        setTimeout(() => {
          fetchDataByUrl(initialUrl);
        }, 3000);

      }

    }).catch((error) => {
      console.log(error)
      if (error.status === 400) {
        return snackbbar(document.querySelector("#body"), circleNike, notRemove, 2000);
      }
    })
  }
  const openStateRemove = (id) => {
    setOpenRemove(true)
    setUserId(id)
  }
  const closePopupRemove = () => {
    setOpenRemove(false)
  }

  return (
    <>
      {isError ? (
        <div className="error_message_connexion">
          Désolé mauvaise connexion svp actualiser la page...
        </div>
      ) : (
        <>
          {openRemove && <div className='masqueOpenModal'></div>}
          {openRemove && <RemovePopup close={close} closePopupRemove = {closePopupRemove} handleRemoveCategories = {handleRemoveCategories} text ="confirmer la suppression de la categorie" rafiki={rafiki} text2 = "Supprimer la categorie"/>
          }
          <table className="user-table">
            <thead>
              <tr>
                <th>{t1}</th>
                <th>{t2}</th>
                <th>{t3}</th>
                <th>{t4}</th>
                <th>{t5}</th>
              </tr>
            </thead>
            <tbody>
              {
                users.map((user, index) => (
                  <>
                    <tr key={user.id}>
                      <td className='rightCategorie'>
                        <img
                          src={rightCategorie}
                          alt=""
                          onClick={() => toggleSousCategories(index)}
                          style={{ transform: expandedIndex === index ? "rotate(90deg)" : "rotate(0deg)" }}
                        />
                      </td>
                      <td>{user.id}</td>
                      <td title={user.name}>{user.name}</td>
                      <td>{user.filesCount}</td>
                      <td>
                        {/* onClick={() => handleRemoveCategories(user.id)} */}
                        <img src={eyes} alt="" onClick={(e) => openDocument(user.id, user.name, user.subCategories)} />
                        <img src={remover} alt="" className='remover' onClick={() => openStateRemove(user.id)} />
                      </td>
                    </tr>

                    {expandedIndex === index && user.subCategories && user.subCategories.length > 0 && (
                      <tr>
                        <td colSpan="5">
                          <div className={`update_sous_categorie_wrapper ${expandedIndex === index ? 'expanded' : ''}`}>
                            {user.subCategories.map((element, i) => (
                              <div className="update_sous_categorie_text1" key={i}>
                                <span className="contentEditable">{element.name}</span>
                                <img
                                  src={remover}
                                  alt=""
                                  className="remove_sous_categories"
                                  onClick={() => handleRemoveSousCategories(element.id)}

                                />
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}

            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageClick}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
          />
        </>
      )}
    </>

  )
}

