import { MainHeader } from "../../repeatableComponents/atomes/mainHeader/MainHeader";
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import Path from "../../../assets/icons/Path.png";
import "./mainTribunaux.css";
import { UserTableTribunaux } from "../../repeatableComponents/Table5/UserTableTribunaux";
import { Pagination } from "../../repeatableComponents/atomes/pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import downForm from "../../../assets/icons/downForm.png";
import closeSelect from "../../../assets/icons/closeSelect.png";
import { fetchData } from "../../../assets/helpers/fetchData";
import { fetchCountries } from "../../../assets/helpers/fetchCountries";
import { useSearchCountries } from "../../../customsHooks/useSearchCountries";
import { useSearchCity } from "../../../customsHooks/useSearchCity";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import circleNike from "../../../assets/icons/circleNike.png"

export const MainTribunaux = () => {
    const [etat, setEtat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [optionVisible, setOptionVisible] = useState(false);
    const [optionVisibleCountriesCity, setOptionVisibleCountriesCity] = useState(false)
    const [dataType, setDataType] = useState([]);
    const [dataCountriesCity, setDataCountriesCity] = useState([])
    const [dataJuridictions, setDataJuridictions] = useState([]);
    const [optionVisibleCity, setOptionVisibleCity] = useState(false);
    const [optionVisibleType, setOptionVisibleType] = useState(false);
    const [optionVisibleJuridictions, setOptionVisibleJuridictions] = useState(false);
    const [checkEtatCity, setCheckEtatCity] = useState(false);
    const [rotateIcon, setRotateIcon] = useState(false);
    const [rotateIconCity, setRotateIconCity] = useState(false);
    const [rotateIconType, setRotateIconType] = useState(false);
    const [rotateIconCountriesCity, setRotateIconCountriesCity] = useState(false);
    const [nameTribunal, setNameTribunal] = useState("");
    const [adressTribunal, setAdressTribunal] = useState("");
    const [countries, setCountries] = useState("");
    const [writeCountries, setWriteCountries] = useState(false);
    const [city, setCity] = useState("");
    const [typeName, setTypeName] = useState("");
    const [typeId, setTypeId] = useState("");
    const [countriesCityName, setCountriesCityName] = useState("")
    const [juridictions, setJuridictions] = useState("");
    const [countriesData, setCountriesData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [etatInput, setEtatInput] = useState(false);
    const [selectedJuridictionsIds, setSelectedJuridictionsIds] = useState([]);
    const [cityId, setCityId] = useState("");
    const [etatInputCity, setEtatInputCity] = useState(false);
    const selectRef = useRef(null);
    const selectRefCity = useRef(null);
    const selectRefType = useRef(null);
    const selectRefCountriesCity = useRef(null)
    const selectRefJuridictions = useRef(null)
    const [searchResults, searchCountries] = useSearchCountries(countriesData);
    const [searchCity, searchCheckCity] = useSearchCity(cityData);
    const [checkCountries, setCheckCountries] = useState(false);
    const [rotateIconJuridictions, setRotateIconJuridictions] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [userTribunalId, setUserTribunalId] = useState("")
    const token = localStorage.getItem("token");
    const messageTribunal = "Tribunal creé"
    const messageTribunal1 = "Tribunal modifié"
    const masque = () => {
        return setEtat(true)
    }
    const closePopup = () => {
        setEtat(false);
        setOpenEyes(false);
        setAdressTribunal("")
        setNameTribunal("")
        setTypeName("")
        setJuridictions("")
        setCountriesCityName("");
    }
    const openDocument = (id, jurisdictionNames, nameCity) => {
        fetchData(`courts/${id}`, "GET", null, token)
            .then((result) => {
                console.log(result)
                setAdressTribunal(result?.result.data.address)
                setNameTribunal(result?.result.data.name)
                setTypeName(result?.result.data.courtType.name)
                setUserTribunalId(result?.result.data.id)
                setTypeId(result?.result.data.courtTypeId)
                setCityId(result?.result.data.cityId)
                const jurisdictionIds = result.result.data.jurisdictions.map(jurisdiction => jurisdiction.id);
                setSelectedJuridictionsIds(jurisdictionIds)

            })
            .catch((error) => {
                console.log(error);
            });
        setJuridictions(jurisdictionNames)
        setCountriesCityName(nameCity);
        setEtat(true);
        setOpenEyes(true);
    }
    const handleChangeSelect = () => {
        setRotateIcon(!rotateIcon);
        const select = selectRef.current
        if (rotateIcon) {
            setEtatInput(false);
            setOptionVisible(false);
            setWriteCountries(false)

        } else {
            setEtatInput(true)
            setOptionVisible(true)
        }
    }
    const handleChangeSelectCity = () => {
        setRotateIconCity(!rotateIconCity);
        if (rotateIconCity) {
            setEtatInputCity(false);
            setOptionVisibleCity(false);
            setCheckEtatCity(false);
        } else {
            setEtatInputCity(true);
            setOptionVisibleCity(true);
        }
    }
    const handleClick = (value, element) => {
        const select = selectRef.current
        setCountries(value);
        setOptionVisible(false);
        setWriteCountries(false)
        setRotateIcon(!rotateIcon);
        searchCountries(value);
        console.log(element.cities)
        setCityData(element?.cities)
        select.style.borderBottomRightRadius = "10px";
        select.style.borderBottomLeftRadius = "10px";
    };
    const handleClickCity = (value) => {
        const select = selectRefCity.current
        setCity(value);
        setOptionVisibleCity(false);
        setCheckEtatCity(false);
        setRotateIconCity(!rotateIconCity);
        searchCheckCity(value);
        select.style.borderBottomRightRadius = "10px";
        select.style.borderBottomLeftRadius = "10px";
    };
    const handleClickType = (value, id) => {
        const select = selectRefType.current
        setTypeName(value)
        setOptionVisibleType(false);
        setTypeId(id);
        //  select.style.borderBottomRightRadius = "10px";
        //  select.style.borderBottomLeftRadius = "10px";
    }

    const handleClickJuridictions = (value, id) => {
        const select = selectRefJuridictions.current;
        if (!juridictions.includes(value)) {
            // Ajouter la nouvelle juridiction à celles déjà sélectionnées, séparées par des virgules
            setJuridictions(prevJuridictions => prevJuridictions ? `${prevJuridictions}, ${value}` : value);
            setSelectedJuridictionsIds(prevIds => [...prevIds, id]);
        }
        setOptionVisibleJuridictions(false);
    };
    const handleClickCountriesCity = (value, id) => {
        const select = selectRefCountriesCity.current
        setCountriesCityName(value)
        setOptionVisibleCountriesCity(false);
        setCityId(id)
        //  select.style.borderBottomRightRadius = "10px";
        //  select.style.borderBottomLeftRadius = "10px";
    }
    const closeSelectInput = () => {
        setEtatInput(false)
        setOptionVisible(false)
        setRotateIcon(false)
        setWriteCountries(false)
    }
    const closeSelectInputCity = () => {
        setEtatInputCity(false);
        setOptionVisibleCity(false);
        setRotateIconCity(false);
    }
    useEffect(() => {
        if (etatInput && countries.length === 0) {
            setOptionVisible(true);
        }
    }, [etatInput, countries]);

    function fetchDataUrl(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                console.log(result?.result?.data)
                setCountriesData(result?.result?.data)
                localStorage.setItem("countriesCity", JSON.stringify(result))
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function fetchDataCourtsType(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setDataType(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function fetchDataJuridictions(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                console.log(result?.result?.data)
                setDataJuridictions(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function fetchDataCountryCity(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                //console.log(result?.result?.data)
                setDataCountriesCity(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    function fetchDataCity(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                //console.log(result?.result?.data)
                setCityData(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlCity = "cities/all"
        fetchDataCity(urlCity)
    }, [])
    useEffect(() => {
        const urlCountryCity = "countries/list"
        fetchDataCountryCity(urlCountryCity)
    }, [])
    useEffect(() => {
        const urlCourJuridictions = "jurisdictions/list"
        fetchDataJuridictions(urlCourJuridictions)
    }, [])
    useEffect(() => {
        const urlCourtypes = "court-types/list"
        fetchDataCourtsType(urlCourtypes)
    }, [])
    useEffect(() => {
        const urlCountries = "countries/list"
        fetchDataUrl(urlCountries)
    }, [])

    const handleAllDatas = () => {
        localStorage.setItem("updateAllDatas", true);
        window.dispatchEvent(new Event("updateDatas"));
    };
    const handleSelectTypeTribunal = () => {
        let select = selectRefType.current
        setRotateIconType(!rotateIconType);
        if (rotateIconType) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleType(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleType(true);
        }
    }
    const handleSelectJuridictions = () => {
        let select = selectRefJuridictions.current
        setRotateIconJuridictions(!rotateIconJuridictions);
        if (rotateIconJuridictions) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleJuridictions(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleJuridictions(true)

        }
    }
    const handleSelectCountiesCity = () => {
        let select = selectRefCountriesCity.current
        setRotateIconCountriesCity(!rotateIconCountriesCity);
        if (rotateIconCountriesCity) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleCountriesCity(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleCountriesCity(true)

        }
    }
    /***debut handle submit */
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(true)
        const fields = [
            { name: 'Nom du tribunal', value: nameTribunal },
            { name: 'Type de tribunal', value: typeName },
            { name: 'Juridiction(s)', value: juridictions },
            { name: 'Ville', value: countriesCityName },
            { name: 'Adresse du tribunal', value: adressTribunal }
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const data = {
            cityId: cityId,
            courtTypeId: typeId,
            jurisdictionIds: selectedJuridictionsIds,
            name: nameTribunal,
            address: adressTribunal
        }
        console.log(data)
        try {

            setLoading(true);
            const result = await fetchData("courts", "POST", data, token)
            console.log(result)
            if (result.status === 201) {
                snackbbar(document.querySelector("#body"), circleNike, messageTribunal, 2000);
                setTimeout(() => {
                    fetchData(
                        "courts?sorts%5Bcourts.created_at%5D=desc&page=1",
                        "GET",
                        null,
                        token
                    ).then((response) => {
                        console.log(response);
                        localStorage.setItem("result", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedResult"));
                        setEtat(false);
                        setAdressTribunal("")
                        setNameTribunal("")
                        setTypeName("")
                        setJuridictions("")
                        setCountriesCityName("");
                    });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    };
    /***fin handle submit */
    const handlePut = async (e) => {
        e.preventDefault();
        console.log(true)
        const fields = [
            { name: 'Nom du tribunal', value: nameTribunal },
            { name: 'Type de tribunal', value: typeName },
            { name: 'Juridiction(s)', value: juridictions },
            { name: 'Ville', value: countriesCityName },
            { name: 'Adresse du tribunal', value: adressTribunal }
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);
            }
        }
        const data = {
            cityId: cityId,
            courtTypeId: typeId,
            jurisdictionIds: selectedJuridictionsIds,
            name: nameTribunal,
            address: adressTribunal
        }
        console.log(data)
        try {

            setLoading(true);
            const result = await fetchData(`courts/${userTribunalId}`, "PUT", data, token)
            if (result.status === 200) {
                snackbbar(document.querySelector("#body"), circleNike, messageTribunal1, 2000);
                setTimeout(() => {
                    fetchData(
                        "courts?sorts%5Bcourts.created_at%5D=desc&page=1",
                        "GET",
                        null,
                        token
                    ).then((response) => {
                        console.log(response);
                        localStorage.setItem("result", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedResult"));
                        setEtat(false);
                        setAdressTribunal("")
                        setNameTribunal("")
                        setTypeName("")
                        setJuridictions("")
                        setCountriesCityName("");
                    });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }

    }



    return (
        <div className="main_accueil">
            {etat && <div className="popup_wrapper">
                <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                    {etat && openEyes ? (
                        <HeaderForm
                            text={`Tribunal ${userTribunalId}`}
                            closePopup={closePopup}
                        />
                    ) : (

                        (
                            <HeaderForm
                                text="Nouveau Tribunal"
                                closePopup={closePopup}
                            />
                        )
                    )}

                    <div className="children_form">
                        <label htmlFor="">Nom du Tribunal</label>
                        <input type="text" placeholder="Nom du tribunal" value={nameTribunal} onChange={(e) => setNameTribunal(e.target.value)} />
                    </div>
                    <div className="children_form" onClick={handleSelectTypeTribunal} ref={selectRefType}>
                        <label htmlFor="">Type de Tribunal</label>
                        <input type="text" placeholder="Haute Instance" value={typeName} />
                        <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconType && "rotate(180deg)" }} />
                        {optionVisibleType && (
                            <div className="optionCity">
                                {
                                    dataType?.map((element) => {
                                        return (
                                            <span key={element.id} onClick={() => handleClickType(element.name, element.id)} >
                                                {element.name}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                    <div className="children_form" onClick={handleSelectJuridictions} ref={selectRefJuridictions}>
                        <label htmlFor="">Juridiction(s)</label>
                        <input type="text" placeholder="Juridiction(s)" value={juridictions} className="input_juridiction" title={juridictions} onChange={(e) => {
                            setJuridictions(e.target.value)
                            if (juridictions.length === 1) {

                                setSelectedJuridictionsIds([])
                            }
                            console.log(juridictions)
                        }} />
                        <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconJuridictions && "rotate(180deg)" }} />
                        {optionVisibleJuridictions && (
                            <div className="optionCity">
                                {

                                    dataJuridictions?.map((element) => {
                                        return (
                                            <span key={element.id} onClick={() => handleClickJuridictions(element.name, element.id)} >
                                                {element.name}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                    <div className="children_form" onClick={handleSelectCountiesCity} ref={selectRefCountriesCity}>
                        <label htmlFor="">Ville</label>
                        <input type="text" placeholder="Ville" value={countriesCityName} />
                        <img src={downForm} alt="" className="down_form" style={{ transform: rotateIconCountriesCity && "rotate(180deg)" }} />
                        {optionVisibleCountriesCity && (
                            <div className="optionCountryCity">

                                {

                                    dataCountriesCity?.map((element) => {
                                        return (
                                            <div className="countriesCity" key={element.id}>
                                                <span className="countriesCity_span">{element.name}</span>
                                                {
                                                    element.cities.map((city) => {
                                                        return (
                                                            <div className="AllcountriesCity" key={city.id}>
                                                                <span className="AllcountriesCity_span" onClick={() => handleClickCountriesCity(city.name, city.id)} >
                                                                    {city.name}
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                    <div className="parent_textarea">
                        <label htmlFor="">Adresse</label>
                        <textarea placeholder="Adresse" value={adressTribunal} onChange={(e) => setAdressTribunal(e.target.value)} />
                    </div>
                    <div className="parent_button_tribunal">
                        <span></span>
                        {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal" type="submit" onClick={handlePut}>Modifier le Tribunal</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter Un Tribunal</button>
                                )}
                            </>
                        )}

                    </div>

                </form>
            </div>}

            <MainHeader text="Tribunal" values={searchCity.length >= 1 ? city : (searchResults.length >= 1 ? countries : undefined)} />
            <div className="all_filter">
                <UpdateTribunal masque={masque} text="+ Ajouter Tribunal" />
                <div className="flex_filter">
                    <div className="parent_filter">
                        <span className="sous_filter1">Filtrer par</span>
                        <div className="checkbox_filter" onClick={handleChangeSelect}>
                            <span className="checkbox_filter_span">Pays</span>
                            <img src={Path} alt="" style={{ transform: rotateIcon && "rotate(180deg)" }} />
                        </div>
                        <div className="checkbox_filter" onClick={handleChangeSelectCity}>
                            <span className="checkbox_filter_span">Ville</span>
                            <img src={Path} alt="" style={{ transform: rotateIconCity && "rotate(180deg)" }} />
                        </div>
                        <span className="sous_filter2" onClick={handleAllDatas}>Tout afficher</span>
                    </div>
                    <div className="select_parent_filter">
                        <div className="select_parent_filter1">
                            {etatInput && <input type="text" className="select_input" value={countries} ref={selectRef} onChange={(e) => {
                                setCountries(e.target.value)
                                searchCountries(e.target.value)
                                setWriteCountries(true)
                                setOptionVisible(false)
                                if (e.target.value.length === 0) {
                                    setOptionVisible(false)
                                }
                            }} />}
                            {etatInput && <img src={closeSelect} alt="" className="close_select" onClick={closeSelectInput} />}
                            {optionVisible && (
                                <div className="option">
                                    {
                                        countriesData?.map((element) => {
                                            return (
                                                <span key={element.id} onClick={() => handleClick(element.name, element)}>
                                                    {element.name}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {writeCountries && (
                                <div className="option">
                                    {searchResults.length > 0 &&
                                        searchResults.map((element) => (
                                            <span key={element.id} onClick={() => handleClick(element.name, element)}>
                                                {element.name}
                                            </span>
                                        ))
                                    }

                                    {searchResults.length === 0 && (
                                        <span className="no-countries">Aucun résultat</span>
                                    )}
                                </div>

                            )}
                        </div>
                        <div className="select_parent_filter2">
                            {etatInputCity && <input type="text" className="select_input" value={city} ref={selectRefCity} onChange={(e) => {
                                setCity(e.target.value);
                                searchCheckCity(e.target.value);
                                setCheckEtatCity(true)
                                setOptionVisibleCity(false)
                                if (e.target.value.length === 0) {
                                    setOptionVisibleCity(false)
                                }

                            }} />}
                            {etatInputCity && <img src={closeSelect} alt="" className="close_select" onClick={closeSelectInputCity} />}
                            {optionVisibleCity && (
                                <div className="option">
                                    {
                                        cityData?.map((element) => {
                                            return (
                                                <span key={element.id} onClick={() => handleClickCity(element.name)}>
                                                    {element.name}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {checkEtatCity && (
                                <div className="option">
                                    {searchCity.length > 0 &&
                                        searchCity.map((element) => (
                                            <span key={element.id} onClick={() => handleClickCity(element.name)}>
                                                {element.name}
                                            </span>
                                        ))
                                    }

                                    {searchCity.length === 0 && (
                                        <span className="no-countries">Aucun résultat</span>
                                    )}
                                </div>

                            )}

                        </div>
                    </div>

                </div>


            </div>
            <UserTableTribunaux t1="Identifiant" t2="Nom du tribunal" t3="Type" t4="Juridiction" t5="Pays" t6="Ville" t7="Action" openDocument={openDocument} />

        </div>
    )
}