import { useEffect, useRef, useState } from "react";
import search from "../../../../assets/icons/search.png";
import "./mainHeader.css";
import { fetchData } from "../../../../assets/helpers/fetchData";

export const MainHeaderTypesJuridictions = ({ text, values }) => {
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState(values || "");  
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role")

  useEffect(() => {
    if (values) {
      console.log(values)
      setSearchInput(values);  
    }
  }, [values]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value); 
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (searchInput.length >= 2) {
        fetchData(
          `users?sorts%5Busers.first_name%5D=asc&search=${searchInput}`,
          "GET",
          null,
          token
        ).then((response) => {
          console.log(response);
          localStorage.setItem("dataJuridictions", JSON.stringify(response));
          localStorage.setItem("searchInputJuridiction",searchInput)
          window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
        });
      }
      if (searchInput.length === 0) {
        fetchData(
          "users?sorts%5Busers.first_name%5D=asc&page=1",
          "GET",
          null,
          token
        ).then((response) => {
          console.log(response);
          localStorage.setItem("dataJuridictions", JSON.stringify(response));
          localStorage.removeItem("searchInputJuridiction");
          window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
        });
      }
    }, 500);

    return () => clearTimeout(timeOut); 
  }, [searchInput, token]); 

  return (
    <div className="parent_header">
      <span className="title_main">{text}</span>
      {role === "administrator" && <div className="search_input">
        <img src={search} alt="" className="search_information" />
        <input
          type="text"
          placeholder="Search"
          ref={inputRef}
          value={searchInput}
          onChange={handleInputChange} 
        />
      </div>}
    </div>
  );
};
