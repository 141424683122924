
import './table.css';
import eyes from "../../../assets/icons/eyes.png";
import remover from "../../../assets/icons/remover.png";
import { Pagination } from '../atomes/pagination/Pagination';
import { useEffect, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';
import circleNike from "../../../assets/icons/circleNike.png";
import { RemovePopup } from './RemovePopup';
import close from "../../../assets/icons/close.png"
import rafiki from "../../../assets/logos/rafiki.png"



export const UserTableTribunaux = ({ t1, t2, t3, t4, t5, t6, t7,openDocument }) => {
  const [userTribunal, setTribunal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 
  const [nextPageUrl, setNextPageUrl] = useState(null); 
  const [prevPageUrl, setPrevPageUrl] = useState(null); 
  const [userId, setUserId] = useState("");
  const [isError,setIsError] = useState(false);
  const [openRemoveDocument, setOpenRemoveDocument] = useState(false);
  const token = localStorage.getItem("token");
  const messageRemove = "suppression reussie"
  const notRemove = "vous ne pouvez pas supprimer un type de tribunal ayant des tribunaux"

  const fetchDataByUrl = (url) => {
    fetchData(url, "GET", null, token)
      .then((result) => {
        const data = result?.result?.data;
        const meta = result?.result?.meta;
        setTribunal(data); 
        setTotalPages(meta?.lastPage || 1);
        setNextPageUrl(meta?.nextPageUrl || null);
        setPrevPageUrl(meta?.previousPageUrl || null);
        setCurrentPage(meta?.currentPage || 1);
      })
      .catch((error) => {
        console.error("Erreur de connexion :", error);
          if(error.message === "Failed to fetch"){
            return  setIsError(true) 
          }    
      });
  };
 // Fonction pour charger les données en temps reel dans mon composant MainHeader
 const loadLocalStorageData = () => {
  const data = JSON.parse(localStorage.getItem("result"));
  if (data) {
    console.log(data);
    const meta = data?.result?.meta;
    setTribunal(data?.result?.data);
     setTotalPages(meta?.lastPage || 1);
     setNextPageUrl(meta?.nextPageUrl || null);
     setPrevPageUrl(meta?.previousPageUrl || null);
     setCurrentPage(meta?.currentPage || 1);
     setIsError(false);
  }
  
};
  useEffect(() => {
    const initialUrl = "courts?sorts%5Bcourts.created_at%5D=desc&page=1";
    fetchDataByUrl(initialUrl);
    // Écouter l'événement en temps reel du tableau contenu dans mon composant de MainHeader
    const handleLocalStorageUpdate = () => {
      loadLocalStorageData();
    };

    window.addEventListener("localStorageUpdatedResult",handleLocalStorageUpdate);

   // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener("localStorageUpdatedResult",handleLocalStorageUpdate);
    };
  }, []);
 
  useEffect(() => {
    const handleUpdateDatas = () => {
      const updateAllDatas = localStorage.getItem("updateAllDatas");

      if(updateAllDatas === "true"){
        const initialUrl = "courts?sorts%5Bcourts.created_at%5D=desc&page=1";
        fetchDataByUrl(initialUrl);
        localStorage.setItem("updateAllDatas", "false");
      }
    };

    window.addEventListener('updateDatas', handleUpdateDatas);

    return () => {
      window.removeEventListener('updateDatas', handleUpdateDatas);
    };
  }, []);
  
  
  


  const handleNextClick = () => {
    if (nextPageUrl) {
      fetchDataByUrl(nextPageUrl);
    }
  };

  const handlePrevClick = () => {
    if (prevPageUrl) {
      fetchDataByUrl(prevPageUrl); 
    }
  };

  const handlePageClick = (pageNumber) => {
    const specificPageUrl = `courts?sorts%5Bcourts.created_at%5D=desc&page=${pageNumber}`;
    fetchDataByUrl(specificPageUrl);
  };
  const removeCourts = () => {
    const initialUrl = "courts?sorts%5Bcourts.created_at%5D=desc&page=1";
    fetchData(`courts/${userId}`,"Delete",null,token).then((response) =>{
      if(response.status === 204){
        snackbbar(document.querySelector("#body"),circleNike,messageRemove, 2000);
        setOpenRemoveDocument(false)
        setTimeout(() => {
          fetchDataByUrl(initialUrl);
      }, 3000);

      }

    }).catch((error) =>{
      console.log(error)
      if(error.status === 400){
      return  snackbbar(document.querySelector("#body"),circleNike,notRemove, 2000);
      }
    })
  }
  const openStateRemove = (id) => {
    setOpenRemoveDocument(true)
    setUserId(id)
  }
  const closePopupRemove = () => {
    setOpenRemoveDocument(false)
  }
  return (
    <>
      {isError ? ( 
        <div className="error_message_connexion">
           Désolé mauvaise connexion svp actualiser la page... 
        </div>
      ) : (
        
        <>
        {openRemoveDocument && <div className='masqueOpenModal'></div>}
        {openRemoveDocument && <RemovePopup close={close} closePopupRemove={closePopupRemove} handleRemoveCategories={removeCourts} text="Voulez vous supprimer ce tribunal" rafiki={rafiki} text2="Confirmer la suppression" />}
          <table className="user-table">
            <thead>
              <tr>
                <th>{t1}</th>
                <th>{t2}</th>
                <th>{t3}</th>
                <th>{t4}</th>
                <th>{t5}</th>
                <th>{t6}</th>
                <th>{t7}</th>
              </tr>
            </thead>
            <tbody>
              {userTribunal?.length > 0 ? (
                userTribunal?.map((user) => (
                 
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td title={user.name}>{user.name}</td>
                    <td title={user.courtType.name}>{user.courtType.name}</td>
                    <td title={user.jurisdictionNames}>{user.jurisdictionNames}</td>
                    <td title={user.country.name}>{user.country.name}</td>
                    <td title={user.city.name}>{user.city.name}</td>
                    <td>
                      <img src={eyes} alt="" onClick={(e) => openDocument(user.id,user.jurisdictionNames,user.city.name)}/>
                      <img src={remover} alt="" className='remover' onClick={(e) => openStateRemove(user.id)}/>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="no-results">Aucun élément ne correspond à cette recherche</td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageClick}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
          />
        </>
      )}
    </>
  );
};
