
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import Path from "../../../assets/icons/Path.png";
import circleNike from "../../../assets/icons/circleNike.png";
import { useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import { UserTableCountries } from "../../repeatableComponents/Table5/UserTableCountries";
import { MainHeaderCountries } from "../../repeatableComponents/atomes/mainHeader/MainHeaderCountries";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";
import { fetchData } from "../../../assets/helpers/fetchData";


export const MainCountries = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [etat, setEtat] = useState(false);
    const [code, setCode] = useState("");
    const [country, setCountry] = useState("");
    const [loading, setLoading] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [idCountries, setIdCountries] = useState("");
    const token = localStorage.getItem("token")
    const handlePageChange = (page) => {
      setCurrentPage(page); 
    };
    const masque= ()=> {
       return setEtat(true)
    }
    const closePopup = ()=>{
        setEtat(false);
        setOpenEyes(false);
    }
    const openDocument = (id,code,name) => {
        setEtat(true);
        setOpenEyes(true);
        setCode(code)
        setCountry(name)
        setIdCountries(id)
    }
      const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = [
            { name: 'code', value: code },
            { name: 'country', value: country },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const data = {
            code:code,
            name: country,
           
        }
       
        try {

            setLoading(true);
            const result = await fetchData("countries", "POST", data, token)
            if (result.status === 201) {
                 snackbbar(document.querySelector("#body"), circleNike,"pays crée avec succès", 2000);
                 setTimeout(() => {
                    fetchData(
                        "countries?sorts%5Bcreated_at%5D=desc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("countries", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedCountries"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
}
const handleInput = async (e) => {
    e.preventDefault();
    const fields = [
        { name: 'code', value: code },
        { name: 'country', value: country },
    ];

    for (let i = 0; i < fields.length; i++) {
        if (!fields[i].value) {
            const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
            return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

        }
    }
    const data = {
        code:code,
        name: country,  
    }
   
    try {

        setLoading(true);
        const result = await fetchData(`countries/${idCountries}`, "PUT", data, token)
        if (result.status === 200) {
             snackbbar(document.querySelector("#body"), circleNike, "modification reussie", 2000);
             setTimeout(() => {
                fetchData(
                    "countries?sorts%5Bcreated_at%5D=desc&page=1",
                    "GET",
                    null,
                    token
                  ).then((response) => {
                    localStorage.setItem("countries", JSON.stringify(response));
                    window.dispatchEvent(new Event("localStorageUpdatedCountries"));
                    setEtat(false);
                  });
            }, 2000);
        }
    } catch (error) {
        console.error(error.message);

    } finally {
        setLoading(false);
    }
}
    return (
        <div className="main_accueil">
             {etat &&<div className="popup_wrapper">
             <div id="masque"></div>
                <form id="answer_form" onSubmit={handleSubmit}>
                <HeaderForm text ="Nouveau Pays" closePopup={closePopup}/>
                <div className="children_form">
                    <label htmlFor="">Code du Pays</label>
                    <input type="text" placeholder="CMR" value={code} onChange={(e) =>setCode(e.target.value)}/>
                </div>
                <div className="children_form">
                    <label htmlFor="">Nom du Pays</label>
                    <input type="text" placeholder="Cameroun" value={country} onChange={(e) =>setCountry(e.target.value)}/>
                </div>
                <div className="parent_button_tribunal">
                    <span></span>
                    {loading ? (
                            <button className="button_tribunal_black">En cours ...</button>
                        ) : (
                            <>
                                {etat && openEyes ? (
                                    <button className="button_tribunal" onClick = {handleInput}>Enregistrer les modifications</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Ajouter le Pays</button>
                                )}
                            </>
                        )}
                </div>
                
                

            </form>
            </div>}
            <MainHeaderCountries text="Pays" />
            <div className="all_filter">
                <UpdateTribunal masque={masque} text="+ Ajouter une Pays"/>
                {/* <div className="parent_filter_countries">
                    <span className="sous_filter1">Filtrer par</span>
                    <div className="checkbox_filter">
                        <span className="checkbox_filter_span">Noms</span>
                        <img src={Path} alt="" />
                    </div>
                    <span className="sous_filter2">Tout afficher</span>
                </div> */}
            </div>
            <UserTableCountries t1="Identifiant" t2="Code du Pays" t3="Nom" t4="Action" openDocument={openDocument}/>

        </div>
    )
}