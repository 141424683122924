import { useEffect, useRef, useState } from "react";
import search from "../../../../assets/icons/search.png";
import "./mainHeader.css";
import { fetchData } from "../../../../assets/helpers/fetchData";

export const MainHeaderDocuments = ({ text, values }) => {
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState(values || "");  
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (values) {
      console.log(values)
      setSearchInput(values);  
    }
  }, [values]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value); 
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (searchInput.length >= 2) {
        fetchData(
          `files?sorts%5Bfiles.created_at%5D=desc&search=${searchInput}`,
          "GET",
          null,
          token
        ).then((response) => {
          console.log(response);
          localStorage.setItem("document", JSON.stringify(response));
          localStorage.setItem("searchInput",searchInput)
          window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
        });
      }
      if (searchInput.length === 0) {
        fetchData(
          "files?sorts%5Bfiles.created_at%5D=desc&page=1",
          "GET",
          null,
          token
        ).then((response) => {
          console.log(response);
          localStorage.setItem("document", JSON.stringify(response));
          localStorage.removeItem("searchInput");
          window.dispatchEvent(new Event("localStorageUpdatedDocuments"));
        });
      }
    }, 500);

    return () => clearTimeout(timeOut); 
  }, [searchInput, token]); 

  return (
    <div className="parent_header">
      <span className="title_main">{text}</span>
      <div className="search_input">
        <img src={search} alt="" className="search_information" />
        <input
          type="text"
          placeholder="Search"
          ref={inputRef}
          value={searchInput}
          onChange={handleInputChange} 
        />
      </div>
    </div>
  );
};
