
import { UpdateTribunal } from "../../repeatableComponents/atomes/updateTribunal/UpdateTribunal";
import Path from "../../../assets/icons/Path.png";
import circleNike from "../../../assets/icons/circleNike.png";
import downForm from "../../../assets/icons/downForm.png";
import { useEffect, useRef, useState } from "react";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import { UserTablesJuridictionsReader } from "../../repeatableComponents/Table5/UserTablesJuridictionsReader";
import { MainHeaderTypesJuridictions } from "../../repeatableComponents/atomes/mainHeader/MainHeaderTypesJuridictions";
import { fetchData } from "../../../assets/helpers/fetchData";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";

export const MainTablesJuridictionsReader = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [etat, setEtat] = useState(false);
    const [openEyes, setOpenEyes] = useState(false);
    const [idJuridictions, setIdJuridictions] = useState("");
    const [firstName, setFirstName] = useState("");
    const [court, setCourt] = useState("");
    const [lastName, setLastName] = useState("");
    const [position, setPosition] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");
    const [positionId, setPositionId] = useState("");
    const [courtId, setCourtId] = useState("");
    const [positionName, setPositionName] = useState("");
    const [optionVisiblePositions, setOptionVisiblePosition] = useState(false);
    const [optionVisibleTribunal, setOptionVisibleTribunal] = useState(false);
    const [optionVisibleRole, setOptionVisibleRole] = useState(false);
    const [rotateIconPosition, setRotateIconPosition] = useState(false);
    const [rotateIconRole, setRotateIconRole] = useState(false);
    const [rotateIconTribunal, setRotateIconTribunal] = useState(false);
    const [positionData, setPositionData] = useState([]);
    const [dataTribunal, setDataTribunal] = useState([]);
    const [loading, setLoading] = useState("");
    const [number, setNumber] = useState("");
    const dataJuridictions = JSON.parse(localStorage.getItem("dataJuridictions"));
    const token = localStorage.getItem('token')
    const dataRole = ["editor","administrator","reader"]
    const selectRefPosition = useRef();
    const selectRefTribunal = useRef();
    const selectRefRole = useRef()
    function fetchDataPositions(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setPositionData(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlPositions = "positions/list"
        fetchDataPositions(urlPositions)
    }, [])
    function fetchDataTribunal(url) {
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                setDataTribunal(result?.result?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlTribunal = "courts/list"
        fetchDataTribunal(urlTribunal)
    }, [])
    const masque = () => {
        return setEtat(true)
    }
    const closePopup = () => {
        setEtat(false)
        setOpenEyes(false);
    }
    const handleSelectPosition = () => {
        let select = selectRefPosition.current
        setRotateIconPosition(!rotateIconPosition);
        if (rotateIconPosition) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisiblePosition(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisiblePosition(true);
        }
    }
    const handleSelectTribunal = () => {
        let select = selectRefTribunal.current
        setRotateIconTribunal(!rotateIconTribunal);
        if (rotateIconTribunal) {
            select.style.borderBottomRightRadius = "10px"
            select.style.borderBottomLeftRadius = "10px"
            setOptionVisibleTribunal(false);
        } else {
            select.style.borderBottomRightRadius = "0px";
            select.style.borderBottomLeftRadius = "0px";
            setOptionVisibleTribunal(true);
        }
    }
    const handleSelectRole = () => {
        let select = selectRefRole.current
        setRotateIconRole(!rotateIconRole);
        if (rotateIconRole) {
            setOptionVisibleRole(false);
        } else {
            setOptionVisibleRole(true);
        }
    }
    const handleClickPosition = (value, id) => {
        setPositionName(value)
        setOptionVisiblePosition(false);
        setPositionId(id);   
    }
    const handleClickTribunal = (value, id) => {
        setCourt(value)
        setOptionVisibleTribunal(false);
        setCourtId(id);   
    }
    const handleClickRole = (value)=>{
        const url = `users?roles=${value}&sorts%5Busers.first_name%5D=asc`
        fetchData(url, "GET", null, token)
            .then((result) => {
                console.log(result)
                console.log(result?.result?.data)
                localStorage.setItem("dataJuridictions", JSON.stringify(result));
                window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
            })
            .catch((error) => {
                console.log(error);
            });
        setRole(value);
        setOptionVisibleRole(false);   
    }
    const openDocument = (id) => {
        setEtat(true);
        setOpenEyes(true);
        setIdJuridictions(id)
        const data = dataJuridictions.result.data.find((element) => element.id === id)
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setRole(data.role)
        setEmail(data.email)
        setPositionName(data.position.name)
        setCourt(data.court.name)
    }
    const handleInput = async (e) =>{
        e.preventDefault();
        const fields = [
            { name: 'Nom', value: firstName },
            { name: 'prenom', value: lastName },
            { name: 'email', value: email },
            { name: 'role', value: role },
            { name: 'tribunaux', value: court },
            { name: 'métier', value: positionName },
            { name: 'number', value: number },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        const dataInfosActor = {
            firstName:firstName,
            lastName: lastName,
            courtId: courtId,
            positionId:positionId,
            email:email,
            role:role,
            phoneNumber:number
        }
       
        try {

            setLoading(true);
            const result = await fetchData(`users/${idJuridictions}`, "PUT", dataInfosActor, token)
            if (result.status === 200) {
                 snackbbar(document.querySelector("#body"), circleNike, "Modification reussie", 2000);
                 setTimeout(() => {
                    fetchData(
                        "users?sorts%5Busers.first_name%5D=asc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("dataJuridictions", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
        
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        const fields = [
            { name: 'Nom', value: firstName },
            { name: 'prenom', value: lastName },
            { name: 'email', value: email },
            { name: 'role', value: role },
            { name: 'tribunaux', value: court },
            { name: 'métier', value: positionName },
             { name: 'télephone', value: number },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        
        const dataInfosActor = {
            firstName:firstName,
            lastName: lastName,
            courtId: courtId,
            positionId:positionId,
            email:email,
            role:role,
            phoneNumber:number
        }
       
        try {

            setLoading(true);
            const result = await fetchData("users", "POST", dataInfosActor, token)
            if (result.status === 201) {
                 snackbbar(document.querySelector("#body"), circleNike, "Acteur crée avec succès", 2000);
                 setTimeout(() => {
                    fetchData(
                        "users?sorts%5Busers.first_name%5D=asc&page=1",
                        "GET",
                        null,
                        token
                      ).then((response) => {
                        localStorage.setItem("dataJuridictions", JSON.stringify(response));
                        window.dispatchEvent(new Event("localStorageUpdatedTypesJuridictions"));
                        setEtat(false);
                      });
                }, 2000);
            }
        } catch (error) {
            console.error(error.message);

        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="main_accueil">
            {etat && <div className="popup_wrapper">
                <div id="masque"></div>
            </div>}
            <MainHeaderTypesJuridictions text="Acteurs Judiciaires" />
            <div className="all_filter">
                <div className="parent_filter_judiciaresReader">
                    <span className="sous_filter_Document">Filtrer par</span>
                    <div className="checkbox_filter_judiciairesRole" onClick={handleSelectRole}>
                        <span className="checkbox_filter_judiciaires_span">Role</span>
                        <img src={Path} alt=""  style={{ transform: rotateIconRole && "rotate(180deg)" }}/>
                        {optionVisibleRole && (
                            <div className="optionRole">
                                {
                                    dataRole?.map((element,index) => {
                                        console.log(element)
                                        return (
                                            <span key={index} onClick={() => handleClickRole(element)} >
                                                {element}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                    <span className="sous_filter2_document">Tout afficher</span>
                </div> 
            </div>
            <UserTablesJuridictionsReader t1="Identifiant" t2="Noms" t3="Prenoms"   t4="Position" t5="Adresse Mail"  t6="Role"/>

        </div>
    )
}