
import './table.css';
import eyes from "../../../assets/icons/eyes.png";
import remover from "../../../assets/icons/remover.png";
import star from "../../../assets/icons/star.png";
import { useEffect, useRef, useState } from 'react';
import { fetchData } from '../../../assets/helpers/fetchData';
import { Pagination } from '../atomes/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import starYellow from "../../../assets/icons/starYellow.png"
import { snackbbar } from '../../../assets/helpers/snackbar/snackBar';
import circleNike from "../../../assets/icons/circleNike.png"
import { RemovePopup } from './RemovePopup';
import close from "../../../assets/icons/close.png"
import rafiki from "../../../assets/logos/rafiki.png"

export const UserTableDocuments = ({ t1, t2, t3, t4, t5, t6, t7, t8, t9, openDocumentDetails}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [openRemoveDocument,setOpenRemoveDocument] = useState(false);
  const [userId,setUserId] = useState("");
  const [favoriteUsers, setFavoriteUsers] = useState({});
  const checkRef = useRef(null);
  const checkColor = useRef(null);
  const [isError, setIsError] = useState(false);
  const  [check, setCheck] = useState(false);
  const token = localStorage.getItem("token");
  const messageRemove = "suppression reussie";
  const navigate = useNavigate();
  
  const handleCkeckPosition = () => {
    const checkPosition = checkRef.current;
    const checkColorRef = checkColor.current;
    setCheck(!check);

    if (check) {
      
      checkPosition.style.transition = "background 0.7s ease";
      checkPosition.style.background = "rgba(0, 0, 0, 0.08)";
      checkColorRef.style.transition = "color 0.7s ease";
      checkColorRef.style.color = "white";
      checkColorRef.style.transition = "transform 0.3s ease";
      checkColorRef.style.transform = "translateX(-1px)";
      checkColorRef.style.background = "white";
     setCheck(false);
      const initialUrl =`files?show_user_document=0&sorts%5Bfiles.created_at%5D=desc`;
      fetchDataByUrl(initialUrl);
    } else {
      
      checkPosition.style.transition = "background 0.7s ease";
      checkPosition.style.background = "#FFB67C";
      checkColorRef.style.transition = "color 0.3s ease, transform 0.3s ease";
      checkColorRef.style.color = "black";
      checkColorRef.style.transform = "translateX(20px)";
      checkColorRef.style.background = "white";
       setCheck(true);
      const initialUrl =`files?show_user_document=1&sorts%5Bfiles.created_at%5D=desc`;
      fetchDataByUrl(initialUrl);
    }
  };
  const retryInterval = 5000;

  const fetchDataByUrl = (url) => {
    fetchData(url, "GET", null, token)
      .then((result) => {
        console.log(result)
        const data = result?.result?.data;
        console.log(data)
        setUsers(data);
        const meta = result?.result?.meta;
        setTotalPages(meta?.lastPage || 1);
        setNextPageUrl(meta?.nextPageUrl || null);
        setPrevPageUrl(meta?.previousPageUrl || null);
        setCurrentPage(meta?.currentPage || 1);
        setIsError(false); // Réinitialise l'erreur si les données sont bien reçues
      })
      .catch((error) => {
        console.error("Erreur de connexion :", error);
        if (error.message === "Failed to fetch") {
          setIsError(true);
          // Réessaie après 5 secondes
          setTimeout(() => {
            fetchDataByUrl(url); // Relance la requête
          }, retryInterval);
        }
      });
  };

  
  // Fonction pour charger les données en temps reel dans mon composant MainHeader
  const loadLocalStorageData = () => {
    const data = JSON.parse(localStorage.getItem("document"));
    if (data) {
      console.log(data);
      const meta = data?.result?.meta;
      console.log(meta)
      setUsers(data?.result?.data);
      setTotalPages(meta?.lastPage || 1);
      setNextPageUrl(meta?.lastPageUrl);
      setPrevPageUrl(meta?.previousPageUrl);
      setCurrentPage(meta?.currentPage || 1);
      setIsError(false);
    }

  };

  useEffect(() => {
    const initialUrl = "files?sorts%5Bfiles.created_at%5D=desc&page=1";
    fetchDataByUrl(initialUrl);
    // Écouter l'événement en temps reel du tableau contenu dans mon composant de MainHeader
    const handleLocalStorageUpdate = () => {
      loadLocalStorageData();
    };

    window.addEventListener("localStorageUpdatedDocuments", handleLocalStorageUpdate);

    // Nettoyage de l'écouteur d'événements
    return () => {
      window.removeEventListener("localStorageUpdatedDocuments", handleLocalStorageUpdate);
    };
  }, []);
  const handleNextClick = () => {
    if (nextPageUrl) {
      fetchDataByUrl(nextPageUrl);
    }
  };

  const handlePrevClick = () => {
    if (prevPageUrl) {
      fetchDataByUrl(prevPageUrl);
    }
  };


const handlePageClick = (pageNumber) => {
  const searchInput = localStorage.getItem("searchInput");
  const status = JSON.parse(localStorage.getItem("documentStatus"));
  
  if (check || searchInput) {
      localStorage.removeItem("documentStatus");
  }

  let specificPageUrl;

  if (status && !check && !searchInput) {
      specificPageUrl = `files?statuses[0]=${status}&sorts%5Bfiles.created_at%5D=desc&page=${pageNumber}`;
  } else if (check) {
      specificPageUrl = `files?show_user_document=1&sorts%5Bfiles.created_at%5D=desc&page=${pageNumber}`;
  } else {
      specificPageUrl = searchInput
          ? `files?sorts%5Bfiles.created_at%5D=desc&search=${searchInput}&page=${pageNumber}`
          : `files?sorts%5Bfiles.created_at%5D=desc&page=${pageNumber}`;
  }

  fetchDataByUrl(specificPageUrl);
};

  
  

  const checkFavoris = (id) => {
    setFavoriteUsers((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id], 
    }));
    const data = {
       isFavourite:!favoriteUsers[id]
    }
    console.log(data)
    if(!favoriteUsers[id] === true){
      console.log(data)
      fetchData(`files/${id}/set-as-favourite`,"PUT",data,token).then((result) =>{
        console.log(result)
        if(result.status === 200){
         return snackbbar(document.querySelector("#body"), circleNike,"document mit en favoris", 2000);
        }
      })
    }else{
      console.log(data)
    }
  };
  useEffect(() => {
    const handleUpdateDatas = () => {
      const updateAllDatas = localStorage.getItem("updateAllDocuments");

      if(updateAllDatas === "true"){
        const initialUrl = "files?sorts%5Bfiles.created_at%5D=desc&page=1";
        fetchDataByUrl(initialUrl);
        localStorage.setItem("updateAllDocuments", "false");
      }
    };

    window.addEventListener('updateAllDocuments', handleUpdateDatas);

    return () => {
      window.removeEventListener('updateAllDocuments', handleUpdateDatas);
    };
  }, []);
  const handleRemoveDocuments = () => {
    const initialUrl = "files?sorts%5Bfiles.created_at%5D=desc&page=1";
    fetchData(`files/${userId}`, "Delete", null, token).then((response) => {
      console.log(response)
      if (response.status === 204) {
        snackbbar(document.querySelector("#body"), circleNike, messageRemove, 2000);
        setOpenRemoveDocument(false);
        setTimeout(() => {
          fetchDataByUrl(initialUrl);
        }, 3000);

      }

    }).catch((error) => {
      console.log(error)
    })
  }
  const openStateRemove = (id) => {
    setOpenRemoveDocument(true)
    setUserId(id)
  }
  const closePopupRemove = () => {
    setOpenRemoveDocument(false)
  }

  return (
    <>
      {isError ? (
        <div className="error_message_connexion">
          Désolé mauvaise connexion svp actualiser la page...
        </div>
      ) : (
        <>
         {openRemoveDocument && <div className='masqueOpenModal'></div>}
         {openRemoveDocument && <RemovePopup close={close} closePopupRemove = {closePopupRemove} handleRemoveCategories = {handleRemoveDocuments} text ="Voulez vous supprimer ce document" rafiki={rafiki} text2 = "confirmer la suppression du document"/>}
        
          <div className="check_all_document">
            <span></span>
            <div className="select_all_favoris-document">
              <span className="displayDocuments">Afficher Mes Documents</span>
              <div className="parent_circle_position" ref={checkRef}>
                <span className="child_circle_position" onClick={handleCkeckPosition} ref={checkColor}></span>
              </div>
            </div>
          </div>
          <table className="user-table">
            <thead>
              <tr>
                <th className='user-table_th'>{t1}</th>
                <th className='user-table_th'>{t2}</th>
                <th className='user-table_th'>{t3}</th>
                <th className='user-table_th'>{t4}</th>
                <th className='user-table_th'>{t5}</th>
                <th className='user-table_th'>{t6}</th>
                <th className='user-table_th'>{t7}</th>
                <th className='user-table_th'>{t8}</th>
                <th className='user-table_th'>{t9}</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                
                <tr key={user.id}>
                  <td className='user-table_td'>{user.id}</td>
                  <td className='user-table_td' title={user.country.name}>{user.country.name}</td>
                  <td className='user-table_td' title={user.city.name}>{user.city.name}</td>
                  <td className='user-table_td' title={user.court.name}>{user.court.name}</td>
                  <td className='user-table_td' title={user.category.name}>{user.category.name}</td>
                  <td className='user-table_td' title={user.judgementDate}>{user.judgementDate}</td>
                  <td className='user-table_td' title={user.judgementNumber}>{user.judgementNumber}</td>
                  {user.status === "pending" ? <td className="user-table_td status_en_attente">En Attente</td> : <td className="user-table_td status_valide" >Validé</td>}
                  <td className='control_img_document'>
                    {user.status === "pending" ? <img src={star} alt="star" className='star' /> : <img src={favoriteUsers[user.id] ? starYellow : star} alt="star" onClick={() => checkFavoris(user.id)} />}
                    <img src={eyes} alt="" onClick={() => openDocumentDetails(user.id)} />
                    <img src={remover} alt="" onClick={()=>openStateRemove(user.id)}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageClick}
            prevPageUrl={prevPageUrl}
            nextPageUrl={nextPageUrl}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
          />
        </>
      )}
    </>
  );
};
