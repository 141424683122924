
import { useState, useCallback } from 'react';

export function useSearchCountries(arrayInformation) {
  const [searchResults, setSearchResults] = useState([]);

  const searchCountries = useCallback((userInformation) => {
    return new Promise((resolve) => {
      let timeOutId = null;
      clearTimeout(timeOutId);

      timeOutId = setTimeout(() => {
        if (userInformation.length >= 4) {  // Vérification de la longueur
          const searchName = () => {
            return new Promise((resolve) => {
              const searchName = arrayInformation.filter((info) => info.name);
              const searchNamesInfos = searchName.filter((info) => {
                if (info.name.toLowerCase().includes(userInformation.toLowerCase())) {
                  return info;
                }
              });
              return resolve(searchNamesInfos);
            });
          };
          

          Promise.all([searchName()]).then((response) => {
            const results = [...response[0]];
            const filterDoublon = [...new Set(results)];
            setSearchResults(filterDoublon);
            return resolve(filterDoublon);
          });
        } else {
          // Si la longueur de userInformation est inférieure à 4
          setSearchResults([]);
          resolve([]);
        }
      }, 300);
    });
  }, [arrayInformation]);

  return [searchResults, searchCountries];
}
