
// import { useState, useCallback } from "react";

// export function useSearchType(arrayType) {
//   const [searchType, setSearchType] = useState([]);

//   const searchCheckType = useCallback((userInformation) => {
//     return new Promise((resolve) => {
//       let timeOutId = null;
//       clearTimeout(timeOutId);

//       timeOutId = setTimeout(() => {
//         if (!arrayType || !Array.isArray(arrayType)) {
//           // Si arrayType n'est pas un tableau, retourne un tableau vide
//           setSearchType([]);
//           return resolve([]);
//         }

//         if (userInformation.length >= 4) {
//           // Recherche les villes lorsque la longueur du texte est de 4 ou plus
//           const searchNameType = () => {
//             return new Promise((resolve) => {
//               const searchNameType = arrayType.map((info) => info.subCategories);
//               console.log(searchNameType)
//               const searchNameTypeCategories = searchNameType.filter((info)  => info.name)
//               console.log(searchNameTypeCategories)
//               const searchNamesInfosType = searchNameTypeCategories.filter((info) => {
//                 if (info.name.toLowerCase().includes(userInformation.toLowerCase())) {
//                   return info;
//                 }
//               });
//               resolve(searchNamesInfosType);
//             });
//           };

//           Promise.all([searchNameType()]).then((response) => {
//             const results = [...response[0]];
//             const filterDoublon = [...new Set(results)];
//             setSearchType(filterDoublon);
//             resolve(filterDoublon);
//           });
//         } else {
//           // Si la longueur de userInformation est inférieure à 4 ou vide
//           setSearchType([]);
//           resolve([]);
//         }
//       }, 300);
//     });
//   }, [arrayType]);

//   return [searchType, searchCheckType];
// }
import { useState, useCallback } from "react";

export function useSearchType(arrayType) {
  const [searchType, setSearchType] = useState([]);

  const searchCheckType = useCallback((userInformation) => {
    return new Promise((resolve) => {
      let timeOutId = null;
      clearTimeout(timeOutId);

      timeOutId = setTimeout(() => {
        if (!arrayType || !Array.isArray(arrayType)) {
          // Si arrayType n'est pas un tableau, retourne un tableau vide
          setSearchType([]);
          return resolve([]);
        }

        if (userInformation.length >= 4) {
          // Recherche lorsque la longueur du texte est de 4 ou plus
          const searchNameType = () => {
            return new Promise((resolve) => {
              // Aplatir le tableau de sous-catégories
              const searchNameType = arrayType.map((info) => info.subCategories).flat();
              console.log(searchNameType); // Devrait maintenant être une liste aplatie de sous-catégories

              // Filtrer les sous-catégories par nom
              const searchNamesInfosType = searchNameType.filter((info) => {
                if (info.name.toLowerCase().includes(userInformation.toLowerCase())) {
                  return info;
                }
              });

              console.log(searchNamesInfosType); // Sous-catégories filtrées
              resolve(searchNamesInfosType);
            });
          };

          // Résoudre la recherche et mettre à jour le state
          Promise.all([searchNameType()]).then((response) => {
            const results = [...response[0]];
            const filterDoublon = [...new Set(results)];
            setSearchType(filterDoublon);
            resolve(filterDoublon);
          });
        } else {
          // Si la longueur de userInformation est inférieure à 4
          setSearchType([]);
          resolve([]);
        }
      }, 300);
    });
  }, [arrayType]);

  return [searchType, searchCheckType];
}
