export const RemovePopup = ({close,closePopupRemove,handleRemoveCategories,text,rafiki,text2}) => {
    return (
        <div className='openModal'>
            <div className='parent_closeRemove'>
                <span></span>
                <img src={close} alt="close" className='closeRemove' onClick={closePopupRemove} style={{ cursor: "pointer" }} />
            </div>
            <div className='parent_title'>
                <img src={rafiki} alt="rafiki" />
                <h2 className='title_remove'>{text}</h2>
                <button className='button_remove' onClick={handleRemoveCategories}>{text2}</button>
            </div>
        </div>
    )
}